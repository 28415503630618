// Define the initial context state
import React, { useState } from "react";
import { UserContext, UserProviderProps } from "./UserContextConstants";
import { User } from "../Types/User";

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User>();

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
