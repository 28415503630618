import React from "react";
import { FieldData } from "@interfaces";
import { Tooltip } from "antd";
import { ToolTips } from "../../../../tooltip";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";
import { Rule } from "antd/lib/form";
import { Field } from "../TransactionForms/SalesCountModalFormV2";
import { ModalOptions } from "hooks/useModal";
import { StyledSubmitButton } from "../../../../V2/StyledComponents";

export const applyTooltipToModalHeader = (
    title:
        | "New Sale Transaction"
        | "New Deposit In Transaction"
        | "New Deposit Out Transaction"
        | "New Transfer Transaction"
        | "New Cash Count Transaction"
        | "New Payment Transaction"
        | "New Eftpos Count Transaction"
        | "Out Type"
        | "Type Select"
        | "Track Balances"
        | "Tender Split"
        | "Class Split"
        | "Customer Details"
) => (
    <Tooltip
        title={ToolTips.ModalHeaderFields[title]}
        mouseEnterDelay={0.5}
        placement="bottom"
    >
        {title}
    </Tooltip>
);
export const appendVenueName = (
    location: ExtendedLocationItemWithChildren,
    venueNameById:
        | {
              [venueID: string]: string;
          }
        | undefined
) => {
    const venueName =
        venueNameById && location.venue ? venueNameById[location.venue] : undefined;

    if (venueName) return `${venueName}: ${location.name}`;
    return location.name;
};

export const updateSpecificFormField = (
    fieldData: FieldData[],
    setFieldData: (input: FieldData[]) => void,
    keyToMatch: string,
    valueToInsert: string | number | undefined,
    resetTouched = false
) => {
    if (valueToInsert === undefined) return;
    if (fieldData.length === 0) return undefined;
    const INDEX_OF_VALUE = fieldData.findIndex((value) => {
        if (Array.isArray(value.name)) {
            return value.name[0] === keyToMatch;
        } else {
            return value.name === keyToMatch;
        }
    });
    const ARRAY_COPY = [...fieldData];

    const ReplacementObject: { name: string[]; value: any; touched?: boolean } = {
        name: [keyToMatch],
        value: valueToInsert.toString(),
    };
    if (resetTouched) {
        ReplacementObject["touched"] = false;
    }
    ARRAY_COPY.splice(INDEX_OF_VALUE, 1, ReplacementObject);

    setFieldData(ARRAY_COPY);
};

// Old approach without "touched field"
export const fetchFromArrayBasedOnKey = (
    keyToMatch: string,
    arrayToSearch: any[]
) => {
    if (arrayToSearch.length === 0) return undefined;
    const RESULT = arrayToSearch.find((value) => {
        const IsMatch = Object.keys(value).find((key) => key == keyToMatch);
        return IsMatch !== undefined;
    });

    return (Object.values(RESULT)[0] as string) ?? "";
};

export const fetchFromArrayBasedOnKeyWithTouched = (
    keyToMatch: string,
    arrayToSearch: any[]
) => {
    if (arrayToSearch.length === 0) return undefined;
    const RESULT = arrayToSearch.find((value) => {
        const IsMatch = Object.keys(value).find((key) => key == keyToMatch);
        return IsMatch !== undefined;
    });

    return (Object.values(RESULT)[0] as string) ?? "";
};

export const fetchBooleanFromArrayBasedOnKey = (
    keyToMatch: string,
    arrayToSearch: any[]
) => {
    if (arrayToSearch.length === 0) return undefined;
    const RESULT = arrayToSearch.find((value) => {
        const IsMatch = Object.keys(value).find((key) => key == keyToMatch);
        return IsMatch !== undefined;
    });

    return (Object.values(RESULT)[0] as boolean) ?? undefined;
};

export const fetchNumberFromArrayBasedOnKey = (
    keyToMatch: string,
    arrayToSearch: any[]
) => {
    if (arrayToSearch.length === 0) return undefined;
    const RESULT = arrayToSearch.find((value) => {
        const IsMatch = Object.keys(value).find((key) => key == keyToMatch);
        return IsMatch !== undefined;
    });

    if (RESULT === undefined) return undefined;
    const VALUE = parseFloat(Object.values(RESULT)[0] as string);

    if (Number.isNaN(VALUE)) {
        return undefined;
    }
    return VALUE;
};

export const positiveNumberOnlyValidator = (field: Field, otherRules: Rule[]) => {
    if (field.type.name === "Money" && field.type.positiveValueOnly) {
        return [
            ...otherRules,
            {
                validator: (_: any, value: any) => {
                    if (Number(value) >= 0) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error("Please enter a positive number")
                    );
                },
            },
        ] as Rule[];
    } else {
        return [...otherRules];
    }
};

export const MODAL_GENERIC_SUCCESS = (
    openmodal: (title: React.ReactNode, options: ModalOptions) => void,
    closemodal: (callback?: (() => void) | undefined) => void,
    title?: React.ReactNode
) => {
    openmodal(title || "API call successfully", {
        status: "success",
        timed: true,
        timeout: 8000,
        buttons: [
            <StyledSubmitButton
                key={1}
                type="primary"
                onClick={() => {
                    closemodal();
                }}
            >
                OK
            </StyledSubmitButton>,
        ],
    });
};
export const MODAL_GENERIC_CONFIRM = (
    openmodal: (title: React.ReactNode, options: ModalOptions) => void,
    closemodal: (callback?: (() => void) | undefined) => void,
    confirm: (callback?: (() => void) | undefined) => void,
    title?: React.ReactNode
) => {
    openmodal(title || "API call successfully", {
        status: "warning",

        buttons: [
            <StyledSubmitButton
                key={1}
                type="primary"
                onClick={() => {
                    closemodal();
                }}
            >
                NO
            </StyledSubmitButton>,
            <StyledSubmitButton
                key={1}
                type="primary"
                onClick={() => {
                    confirm();
                }}
            >
                YES
            </StyledSubmitButton>,
        ],
    });
};
export const MODAL_GENERIC_ERROR = (
    openmodal: (title: React.ReactNode, options: ModalOptions) => void,
    closemodal: (callback?: (() => void) | undefined) => void,
    callback?: () => void,
    title?: React.ReactNode,
    timeout?: number | null
) => {
    openmodal(title, {
        status: "error",
        timed: timeout !== null,
        timeout: timeout || 8000,
        buttons: [
            <StyledSubmitButton
                key={1}
                type="primary"
                onClick={() => {
                    callback ? closemodal(callback) : closemodal();
                }}
            >
                OK
            </StyledSubmitButton>,
        ],
    });
};

export const MODAL_GENERIC_WARNING = (
    openmodal: (title: React.ReactNode, options: ModalOptions) => void,
    closemodal: (callback?: (() => void) | undefined) => void,
    title: React.ReactNode,
    callback?: () => void
) => {
    openmodal(title, {
        status: "warning",
        buttons: [
            <StyledSubmitButton
                key={0}
                type="default"
                onClick={() => {
                    closemodal();
                }}
            >
                Cancel
            </StyledSubmitButton>,
            <StyledSubmitButton
                key={1}
                type="primary"
                onClick={() => {
                    callback && callback();
                    closemodal();
                }}
            >
                Continue
            </StyledSubmitButton>,
        ],
    });
};

export const ACCESS_DENIED_CONTENT =
    "You don’t have permission to access this module. If you believe this is an error, please contact your Customer Success Manager.";
