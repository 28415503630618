import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Select, Typography } from "antd";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import { Space } from "StyledComponents/Space";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import styled from "styled-components";
import { useQuery } from "react-query";
import { getVenues } from "../../ApiV2/Helpers/getVenues";
import { getGroups } from "ApiV2/Helpers/getGroups";
import { getCashupReport } from "../../ApiV2/Helpers/getCashupReport";
import { apiUrlTypes, areaTypes, reportTypes, SUPPORT_URL } from "./const";
import CashUpReportDatePicker from "./Components/CashupReportDatePicker";
import CashupPosClassesTenderReport from "./Components/CashupPosClassesTendersReport";
import CashupPosVarianceReport from "./Components/CashupPosVarianceReport";
import CashupGamingMeteredNetProfitReport from "./Components/CashupGamingMeteredNetProfitReport";
import CashupPosSummaryReport from "./Components/CashupPosSummaryReport";
import CashupPosVarianceSummaryReport from "./Components/CashupPosVarianceSummaryReport";
import CashupShiftReport from "./Components/ShiftReport/CashupShiftReport";
import CashupGamingActualNetProfitReport from "./Components/CashupGamingActualNetProfit";
import CashupGamingVarianceReport from "./Components/CashupGamingVarianceReport";
import CashupPayoutsReport from "./Components/CashupPayoutsReport";
import CashupPettyCashReport from "./Components/CashupPettyCashReport";
import CashupApprovalsReport from "./Components/CashupApprovalsReport";
import CashupBankableReport from "./Components/BankableReport/CashupBankableReport";
import CashupGroupPosClassesReport from "./Components/GroupReport/CashupGroupPosClassesReport";
import CashupGroupPosTendersReport from "./Components/GroupReport/CashupGroupPosTendersReport";
import CashupGroupPosVariancesReport from "./Components/GroupReport/CashupGroupPosVariancesReport";
import CashupGroupShiftReport from "./Components/GroupReport/ShiftReportGroup/CashupGroupShiftReport";
import CashupGroupGamingMeteredNetProfitReport from "./Components/GroupReport/CashupGroupGamingMeteredNetProfitReport";
import CashupGroupGamingActualNetProfitReport from "./Components/GroupReport/CashupGroupGamingActualNetProfitReport";
import CashupGroupGamingVarianceReport from "./Components/GroupReport/CashupGroupGamingVarianceReport";
import CashupAustracReport from "./Components/AustracReport/CashupAustracReport";
import CashupGamingCRTReport from "./Components/CashupGamingCRT";
import { SelectOptionObject } from "@interfaces/index";
import { Venue } from "../../Types/Venue";
import { Group } from "../../Types/Group";
import { checkDateRange, filterVenueData, updateGroupsData } from "./utils";
import { CashupReportType } from "@Constants/enum/Cashup";
import { useUser } from "../../Context/UserContextConstants";


const { Title } = Typography;

const LoadingContainer = styled.div`
    width: 100%;
    height: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CashupReport: React.FC = () => {
    const [area, setArea] = useState<SelectOptionObject>({} as SelectOptionObject);
    const [venue, setVenue] = useState<SelectOptionObject>({} as SelectOptionObject);
    const [group, setGroup] = useState<SelectOptionObject>({} as SelectOptionObject);
    const [report, setReport] = useState<SelectOptionObject>(
        {} as SelectOptionObject
    );
    const [venueList, setVenueList] = useState<SelectOptionObject[]>([]);
    const [groupList, setGroupList] = useState<SelectOptionObject[]>([]);
    const [reportList, setReportList] = useState<SelectOptionObject[]>([]);
    const [loading, setLoading] = useState(false as boolean);
    const [statusMessage, setStatusMessage] = useState("" as string);
    const [calendarCheck, setCalendarCheck] = useState(false as boolean);
    const [date, setDate] = useState<{ start_date: string; end_date: string }>(
        () => {
            const startOfWeek = dayjs().startOf("week").format("DD-MM-YYYY");
            const endOfWeek = dayjs().endOf("week").format("DD-MM-YYYY");
            return { start_date: startOfWeek, end_date: endOfWeek };
        }
    );
    const [defaultDateRange, setDefaultDateRange] = useState<[Dayjs, Dayjs]>(() => {
        return [dayjs().startOf("week"), dayjs().endOf("week")];
    });
    const [token] = React.useState<string | null>(
        localStorage.getItem("access_token")
    );
    const [venueId, setVenueIdVar] = useState<string>(
        localStorage.getItem("selectedVenueId") || ""
    );

    //get venues list
    const { data: venues, isLoading: venueDataLoading } = useQuery(
        ["venuesData", token],
        () => getVenues()
    );
    //get groups list
    const { data: groups, isLoading: groupDataLoading } = useQuery(
        ["groupsData", token],
        () => getGroups()
    );
    //get user details
    const { user: activeUser } = useUser();

    //set venue options
    useEffect(() => {
        const venueOptions = filterVenueData(venues as any[]);
        const groupOptions = updateGroupsData(groups as Group[]);
        if (venueOptions.length > 0 && groupOptions.length > 0) {
            const sortedGroupList = groupOptions.sort((a, b) =>
                a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            );
            setGroupList(sortedGroupList);
            //add default venue All
            let combinedVenueList = [];
            combinedVenueList.push({
                id: "all",
                value: "all",
                label: "All",
                type: "group",
            });
            // if no pre selected venue
            if (!venueId || venueId.length < 1) {
                setGroup(sortedGroupList[0]);
                const venuesByGroup = venueOptions.filter(
                    (item) => item.group_id === sortedGroupList[0].id
                );
                const sortedVenuesList = venuesByGroup.sort((a, b) =>
                    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                );
                combinedVenueList = combinedVenueList.concat(sortedVenuesList);
                setVenueList(combinedVenueList);
                setVenue(combinedVenueList[1]);
            } else {
                // venue selected in cashup
                const selectedVenue = venueOptions.filter(
                    (item) => item.id === venueId
                )[0];
                const selectedGroup = groupOptions.filter(
                    (item) => item.id === selectedVenue.group_id
                )[0];
                const filteredVenuesByGroup = venueOptions.filter(
                    (item) => item.group_id === selectedVenue.group_id
                );
                const sortedVenuesList = filteredVenuesByGroup.sort((a, b) =>
                    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                );
                combinedVenueList = combinedVenueList.concat(sortedVenuesList);
                setGroup(selectedGroup);
                setVenueList(combinedVenueList);
                setVenue(sortedVenuesList.filter((item) => item.id === venueId)[0]);
            }
        }
    }, [venues, groups, activeUser]);

    //set initial default values
    useEffect(() => {
        //select default area
        const defaultArea = areaTypes[0];
        //filter report types for default area
        const defaultReportTypes = reportTypes.filter(
            (item) => item.areaTypeId === defaultArea.id
        );
        //set report type list
        setReportList(defaultReportTypes[0].options);
        //set initial area and report type
        setArea(defaultArea);
        setReport(defaultReportTypes[0].options[0]);
    }, []);

    //update default dates to yesterday for shift report
    useEffect(() => {
        if (report.value === "shiftReport" || report.value === "bankable") {
            const startOfWeek = dayjs().subtract(1, "day").startOf("day");
            const endOfWeek = dayjs().subtract(1, "day").endOf("day");
            setDefaultDateRange([startOfWeek, endOfWeek]);
            setDate({
                start_date: startOfWeek.format("DD-MM-YYYY"),
                end_date: endOfWeek.format("DD-MM-YYYY"),
            });
            setLoading(true);
        }
    }, [report]);

    const formatDateString = (dateString: string) => {
        return dayjs(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
    };

    // Fetch report data using React Query
    const fetchReportData = async () => {
        if (!(date.start_date.length > 0 && date.end_date.length > 0)) {
            setStatusMessage("");
            return [];
        }
        if (venue && venue.type === "group") {
            setStatusMessage("group");
            return [];
        }
        if (
            ["shiftReport", "bankable", "austracCompliance"].includes(report.value)
        ) {
            setStatusMessage("");
            return [];
        } else {
            try {
                //check date range for all other reports
                if (checkDateRange(date, 60)) {
                    setStatusMessage(
                        "Cashup reports are limited to a 60-day date range. Please adjust your date range selection."
                    );
                    return [];
                } else {
                    if (venue && venue.id && !calendarCheck) {
                        const urlKey = `${venue.type}-${area.value}-${report.value}`;
                        const reportUrlType = apiUrlTypes[urlKey];
                        const formattedStartDate = formatDateString(date.start_date);
                        const formattedEndDate = formatDateString(date.end_date);
                        //get report data
                        const response = await getCashupReport(
                            reportUrlType,
                            venue.id,
                            formattedStartDate,
                            formattedEndDate
                        );
                        setStatusMessage("");
                        return response.json();
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setStatusMessage("");
                throw error;
            }
        }
    };

    const {
        data: reportData = [],
        isLoading: reportDataLoading,
        error: reportError,
        refetch,
    } = useQuery(["reportData"], fetchReportData, {
        enabled:
            !!venue?.id &&
            !!report?.value &&
            !!area?.value &&
            date.start_date.length > 0 &&
            date.end_date.length > 0,
        refetchOnWindowFocus: false,
        onSettled: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        if (loading) {
            refetch();
        }
    }, [loading, refetch]);

    //get onboarding date for the selected venue
    const onboardingDate = useMemo(() => {
        if (!venue) {
            return undefined;
        } else {
            return venues?.find(
                (venueItem: Venue) => venueItem.venue_id === venue.id
            )?.onboarding_date;
        }
    }, [venue, venues]);

    const onAreaChange = (value: string) => {
        if (value) {
            setLoading(true);
            const selectedArea = areaTypes.find((item) => item.value === value);
            if (selectedArea) {
                const selectedReportTypes = reportTypes.filter(
                    (item) => item.areaTypeId === selectedArea.id
                );
                setArea(selectedArea);
                const filteredList =
                    selectedArea.value === "pos" && venue.type === "group"
                        ? selectedReportTypes[0].options.filter(
                              (item) => !item.value.includes("summary")
                          )
                        : selectedReportTypes[0].options;
                setReportList(filteredList);
                setReport(filteredList[0]);
            }
        }
    };

    const onReportChange = (value: string) => {
        if (value) {
            setLoading(true);
            const selectedReport = reportList.find((item) => item.value === value);
            if (selectedReport) {
                setReport(selectedReport);
            }
        }
    };

    const onVenueChange = (value: string) => {
        if (value && venueList) {
            setLoading(true);
            const selectedVenue = venueList.find((item) => item.value === value);
            if (selectedVenue) {
                setVenue(selectedVenue);
                if (area.value === "pos") {
                    const selectedReportTypes = reportTypes.filter(
                        (item) => item.areaTypeId === area.id
                    );
                    const filteredList =
                        selectedVenue.type === "group"
                            ? selectedReportTypes[0].options.filter(
                                  (item) => !item.value.includes("summary")
                              )
                            : selectedReportTypes[0].options;
                    const selectedReport = filteredList.find(
                        (item) => item.value === report.value
                    );
                    setReportList(filteredList);
                    setReport(selectedReport ? selectedReport : filteredList[0]);
                }
            }
        }
    };

    const onGroupChange = (value: string) => {
        if (value && groupList) {
            setLoading(true);
            const selectedGroup = groupList.find((item) => item.value === value);
            if (selectedGroup) {
                setGroup(selectedGroup);
                const venueOptions = filterVenueData(venues as any[]);
                let combinedVenueList = [];
                combinedVenueList.push({
                    id: "all",
                    value: "all",
                    label: "All",
                    type: "group",
                });
                const filteredVenuesByGroup = venueOptions.filter(
                    (item) => item.group_id === selectedGroup.id
                );
                const sortedVenuesList = filteredVenuesByGroup.sort((a, b) =>
                    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                );
                combinedVenueList = combinedVenueList.concat(sortedVenuesList);
                setVenueList(combinedVenueList);
                //set the first venue
                setVenue(combinedVenueList[1]);
                //filter pos reports
                if (area.value === "pos") {
                    const selectedReportTypes = reportTypes.filter(
                        (item) => item.areaTypeId === area.id
                    );
                    const filteredReportList =
                        combinedVenueList[1].type === "group"
                            ? selectedReportTypes[0].options.filter(
                                  (item) => !item.value.includes("summary")
                              )
                            : selectedReportTypes[0].options;
                    const selectedReport = filteredReportList.find(
                        (item) => item.value === report.value
                    );
                    setReportList(filteredReportList);
                    setReport(
                        selectedReport ? selectedReport : filteredReportList[0]
                    );
                }
            }
        }
    };

    const getReportType = () => {
        const reportKey = `${venue.type}-${area.value}-${report.value}`;
        switch (reportKey) {
            case CashupReportType.PosClasses:
            case CashupReportType.PosTenders:
                return (
                    <CashupPosClassesTenderReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.PosVariances:
                return (
                    <CashupPosVarianceReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.PosClassesSummary:
            case CashupReportType.PosTendersSummary:
                return (
                    <CashupPosSummaryReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.PosVariancesSummary:
                return (
                    <CashupPosVarianceSummaryReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.GamingMeteredNetProfit:
                return (
                    <CashupGamingMeteredNetProfitReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.GamingActualNetProfit:
                return (
                    <CashupGamingActualNetProfitReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.GamingVariance:
                return (
                    <CashupGamingVarianceReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.GamingPayouts:
            case CashupReportType.WageringPayouts:
                return (
                    <CashupPayoutsReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.GamingCRT:
                return (
                    <CashupGamingCRTReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.TransactionsPettyCash:
                return (
                    <CashupPettyCashReport
                        reportData={reportData}
                        areaName={area.label}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.AdminShiftReport:
                return (
                    <CashupShiftReport
                        reportType={report.label}
                        date={date}
                        venue={venue.id}
                        venueName={venue.label}
                        calendarStatus={calendarCheck}
                    />
                );
            case CashupReportType.ApprovalsReport:
                return (
                    <CashupApprovalsReport
                        reportData={reportData}
                        reportType={report.label}
                        date={date}
                        venueName={venue.label}
                    />
                );
            case CashupReportType.BankableReport:
                return (
                    <CashupBankableReport
                        reportType={report.label}
                        date={date}
                        venue={venue.id}
                        venueName={venue.label}
                        calendarStatus={calendarCheck}
                    />
                );
            case CashupReportType.AustracCompliance:
                return (
                    <CashupAustracReport
                        reportType={report.label}
                        date={date}
                        venue={venue.id}
                        venueName={venue.label}
                        calendarStatus={calendarCheck}
                    />
                );
            case CashupReportType.GroupPosClasses:
                return (
                    <CashupGroupPosClassesReport
                        reportType={report.label}
                        date={date}
                        group={group.id}
                        groupName={group.label}
                        areaName={area.label}
                        calendarStatus={calendarCheck}
                    />
                );
            case CashupReportType.GroupPosTenders:
                return (
                    <CashupGroupPosTendersReport
                        reportType={report.label}
                        date={date}
                        group={group.id}
                        groupName={group.label}
                        areaName={area.label}
                        calendarStatus={calendarCheck}
                    />
                );
            case CashupReportType.GroupPosVariances:
                return (
                    <CashupGroupPosVariancesReport
                        reportType={report.label}
                        date={date}
                        group={group.id}
                        groupName={group.label}
                        areaName={area.label}
                        calendarStatus={calendarCheck}
                    />
                );
            case CashupReportType.GroupGamingMeteredNetProfit:
                return (
                    <CashupGroupGamingMeteredNetProfitReport
                        reportType={report.label}
                        date={date}
                        group={group.id}
                        groupName={group.label}
                        areaName={area.label}
                        calendarStatus={calendarCheck}
                    />
                );
            case CashupReportType.GroupGamingActualNetProfit:
                return (
                    <CashupGroupGamingActualNetProfitReport
                        reportType={report.label}
                        date={date}
                        group={group.id}
                        groupName={group.label}
                        areaName={area.label}
                        calendarStatus={calendarCheck}
                    />
                );
            case CashupReportType.GroupGamingVariance:
                return (
                    <CashupGroupGamingVarianceReport
                        reportType={report.label}
                        date={date}
                        group={group.id}
                        groupName={group.label}
                        areaName={area.label}
                        calendarStatus={calendarCheck}
                    />
                );
            case CashupReportType.GroupAdminShiftReport:
                return (
                    <CashupGroupShiftReport
                        reportType={report.label}
                        date={date}
                        group={group.id}
                        groupName={group.label}
                        areaName={area.label}
                        calendarStatus={calendarCheck}
                    />
                );
            default:
                return (
                    <Title level={5}>
                        This Cashup report is not yet available for the group level
                        filter.
                    </Title>
                );
        }
    };

    return (
        <>
            {venueDataLoading && groupDataLoading && venueList.length < 1 ? (
                <LoadingContainer>
                    <CentredSpinner size="large" />
                </LoadingContainer>
            ) : (
                <Space
                    $fluid
                    size="middle"
                    direction="vertical"
                    style={{ padding: 24 }}
                >
                    <Row gutter={[20, 8]}>
                        <Col xs={{ order: 1 }}>
                            <Row style={{ marginBottom: 5 }}>
                                <label htmlFor="select4">Group</label>
                            </Row>
                            <Row>
                                <Select
                                    value={group?.value}
                                    style={{ width: 200 }}
                                    onChange={onGroupChange}
                                    options={groupList}
                                />
                            </Row>
                        </Col>
                        <Col xs={{ order: 2 }}>
                            <Row style={{ marginBottom: 5 }}>
                                <label htmlFor="select4">Venue</label>
                            </Row>
                            <Row>
                                <Select
                                    value={venue?.value}
                                    style={{ width: 200 }}
                                    onChange={onVenueChange}
                                    options={venueList}
                                />
                            </Row>
                        </Col>
                        <Col xs={{ order: 3 }}>
                            <Row style={{ marginBottom: 5 }}>
                                <label htmlFor="select4">Area</label>
                            </Row>
                            <Row>
                                <Select
                                    value={area.value}
                                    style={{ width: 200 }}
                                    onChange={onAreaChange}
                                    options={areaTypes}
                                />
                            </Row>
                        </Col>
                        <Col xs={{ order: 4 }}>
                            <Row style={{ marginBottom: 5 }}>
                                <label htmlFor="select4">Report</label>
                            </Row>
                            <Row>
                                <Select
                                    value={report.value}
                                    style={{ width: 200 }}
                                    onChange={onReportChange}
                                    options={reportList}
                                />
                            </Row>
                        </Col>
                        <Col xs={{ order: 5 }}>
                            <Row style={{ marginBottom: 5 }}>
                                <label htmlFor="select4">Date</label>
                            </Row>
                            <Row>
                                <div>
                                    <CashUpReportDatePicker
                                        onboardingDate={onboardingDate}
                                        setDate={setDate}
                                        setLoading={setLoading}
                                        reportType={report.value}
                                        defaultDateRange={defaultDateRange}
                                        setCalendarCheck={setCalendarCheck}
                                    />
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    {loading ? (
                        <LoadingContainer>
                            <CentredSpinner size="large" />
                        </LoadingContainer>
                    ) : ["shiftReport", "bankable", "austracCompliance"].includes(
                          report.value
                      ) ? (
                        <>
                            {statusMessage.length > 0 &&
                            !statusMessage.includes("group") ? (
                                <Title level={5}>{statusMessage}</Title>
                            ) : (
                                <>{getReportType()}</>
                            )}
                        </>
                    ) : reportError ? (
                        <Title level={5}>
                            There was an error when trying to retrieve report data.
                            Please contact{" "}
                            <a
                                href={SUPPORT_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Quantaco Support
                            </a>{" "}
                            for assistance.
                        </Title>
                    ) : reportData?.length > 0 ? (
                        <>{getReportType()}</>
                    ) : (
                        <>
                            {statusMessage.length > 0 ? (
                                statusMessage.includes("group") ? (
                                    getReportType()
                                ) : (
                                    <Title level={5}>{statusMessage}</Title>
                                )
                            ) : (
                                <Title level={5}>
                                    Cashup data is not available for the selected
                                    combination of filters. Please review and update
                                    your selection.
                                </Title>
                            )}
                        </>
                    )}
                </Space>
            )}
        </>
    );
};

export default CashupReport;
