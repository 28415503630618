const spacingUnit = 8;
export const level1Spacing = spacingUnit;
export const level2Spacing = spacingUnit * 2;
export const level3Spacing = spacingUnit * 3;
export const smallSpacing = spacingUnit / 2;

export const QUANTACO_PEACH = "#FF4E64";
export const QUANTACO_INFO_BLUE = "#3448F0";
export const QUANTACO_PEACH_10 = "#FF4E641F";
export const QUANTACO_INFO_BLUE_10 = "#3448F01F";
