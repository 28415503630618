import { Button } from "antd";

import { SubmissionStatus } from "Redux/StateSlices/GroupData/VenuesAPI";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { QUANTACO_ORANGE } from "../../@Constants/theme/constants";
import { fetchWrapper } from "../../API";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import {
    MODAL_GENERIC_ERROR,
    MODAL_GENERIC_SUCCESS,
    MODAL_GENERIC_WARNING,
} from "../../Pages/CashupHome/ExcelTable/utils";
import { useContextModal } from "../../hooks/useModal";
import { StyledStatistic, SubmitSection } from "../StyledComponents";
import DownloadJournalButton from "./DownloadJournalButton";
import { useUser } from "../../Context/UserContextConstants";

const StyledButton = styled(Button)`
    margin-right: 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
`;

const SubmitButton = styled(StyledButton)<{
    backgroundcolor: string;
    bordcolor: string;
}>`
    background-color: ${(props) => props.backgroundcolor};
    border: 2px solid ${(props) => props.bordcolor};
    width: 110px;
    box-shadow: none;
`;
const SubmitApproveSection: React.FC<{
    submitted_by: string;
    status: string;
    approved_by: string;
    selectedVenueId: string;
    selectedDate: string;
}> = ({ status, selectedVenueId, selectedDate }) => {
    const [token] = React.useState<string | null>(
        localStorage.getItem("access_token")
    );
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const [isApprovedDisabled, setApproveDisabled] = useState(false);

    const { setShiftStatus, shiftStatus: stat } = useVenueSelection();
    const { user: activeUser } = useUser();
    console.log(activeUser);
    console.log("activeUser");
    const capitalizedStatus = useMemo(() => {
        return stat.charAt(0).toUpperCase() + stat.slice(1).toLowerCase();
    }, [stat]);
    useEffect(() => {
        console.log("");
        if (isSumbitButtonDisabled()) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
        if (isApproveButtonDisabled()) {
            setApproveDisabled(true);
        } else {
            setApproveDisabled(false);
        }
    }, [status, capitalizedStatus, activeUser]);
    const { openModal, closeModal } = useContextModal();

    const { mutate: submitTable } = useMutation(
        async () =>
            await fetchWrapper(
                `/api/shift-dates/${dayjs(selectedDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                )}/${selectedVenueId}/submit/`,
                "POST",
                JSON.stringify({ confirm_submission: "True" })
            ),
        {
            onMutate: () => {
                setSubmitDisabled(true); // Disable button at the start
            },
            onSuccess: (response) => {
                if (response.ok) {
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Cashup submitted successfully"
                    );
                    setShiftStatus(SubmissionStatus.SUBMITTED);
                } else {
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        submitTable,
                        "Cashup submission failed, try again?"
                    );
                }
            },
            onSettled: () => {
                setSubmitDisabled(false); // Re-enable button after request completes
            },
        }
    );

    const { mutate: unsubmitTable } = useMutation(
        async () =>
            await fetchWrapper(
                `/api/shift-dates/${dayjs(selectedDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                )}/${selectedVenueId}/unsubmit/`,
                "POST"
            ),
        {
            onMutate: () => {
                setSubmitDisabled(true); // Disable button at the start
            },
            onSuccess: async (response) => {
                if (response.ok) {
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Cashup unsubmitted successfully"
                    );
                    setShiftStatus(SubmissionStatus.UNSUBMITTED);
                } else {
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        unsubmitTable,
                        "Cashup unsubmission failed, try again?"
                    );
                }
            },
            onSettled: () => {
                setSubmitDisabled(false); // Re-enable button after request completes
            },
        }
    );
    const { mutate: approveTable } = useMutation(
        async () =>
            await fetchWrapper(
                `/api/shift-dates/${dayjs(selectedDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                )}/${selectedVenueId}/approve/`,
                "POST"
            ),
        {
            onMutate: () => {
                setApproveDisabled(true); // Disable button at the start
            },
            onSuccess: async (response) => {
                if (response.ok) {
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Cashup approved successfully"
                    );
                    setShiftStatus(SubmissionStatus.APPROVED);
                } else {
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        approveTable,
                        "Cashup approve failed, try again?"
                    );
                }
            },
            onSettled: () => {
                setApproveDisabled(false); // Re-enable button after request completes
            },
        }
    );

    const { mutate: unapproveTable } = useMutation(
        async () =>
            fetchWrapper(
                `/api/shift-dates/${dayjs(selectedDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                )}/${selectedVenueId}/unapprove/`,
                "POST"
            ),
        {
            onMutate: () => {
                setApproveDisabled(true); // Disable button at the start
            },
            onSuccess: async (response) => {
                if (response.ok) {
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Cashup unapproved successfully"
                    );
                    setShiftStatus(SubmissionStatus.UNSUBMITTED);
                } else {
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        unapproveTable,
                        "Cashup unapprove failed, try again?"
                    );
                }
            },
            onSettled: () => {
                setApproveDisabled(false); // Re-enable button after request completes
            },
        }
    );

    const onClickSubmit = () =>
        MODAL_GENERIC_WARNING(
            openModal,
            closeModal,
            <div>
                Please ensure you have inspected all outstanding variances in the
                Review Tab, before submitting your Cashup.
                <br />
                Click continue to submit.
            </div>,
            submitTable
        );
    const onClickApprove = () =>
        MODAL_GENERIC_WARNING(
            openModal,
            closeModal,
            <div>
                Please ensure that all information on this Cashup is complete and
                accurate.
                <br />
                The Review Tab can assist you in locating variances.
                <br />
                Click continue to Approve
            </div>,
            approveTable
        );

    const getSubmitButtonText = () => {
        return capitalizedStatus === "Unsubmitted" ? "Submit" : "Unsubmit";
    };

    const getSubmitButtonClick = () => {
        if (capitalizedStatus === "Unsubmitted") {
            onClickSubmit();
        }
        if (capitalizedStatus === "Submitted") {
            unsubmitTable();
        }
    };

    const isSumbitButtonDisabled = () => capitalizedStatus === "Approved";

    const getApproveButtonText = () => {
        return capitalizedStatus === "Approved" ? "Unapprove" : "Approve";
    };

    const getApproveButtonClick = () => {
        if (capitalizedStatus === "Submitted") {
            onClickApprove();
        }
        if (capitalizedStatus === "Approved") {
            unapproveTable();
        }
    };

    const isApproveButtonDisabled = () => {
        const { role } = activeUser || { role: "" };
        const isAdmin = ["SUPER_ADMIN", "QUANTACO_ADMIN"].includes(role);
        const isOwnerOrManager = ["OWNER", "MANAGER"].includes(role);
        switch (capitalizedStatus) {
            case "Submitted":
                // Approve enabled for all specified roles
                return !(isAdmin || isOwnerOrManager);
            case "Approved":
                // Unapprove enabled only for SUPER_ADMIN or QUANTACO_ADMIN
                return !isAdmin;
            default:
                return true;
        }
    };

    return (
        <SubmitSection>
            <StyledStatistic
                title="Status"
                value={capitalizedStatus}
                valueStyle={{ fontSize: "14px" }}
            />

            <SubmitButton
                backgroundcolor="#00225D"
                bordcolor="#000"
                size="large"
                type="primary"
                disabled={isSubmitDisabled}
                onClick={() => getSubmitButtonClick()}
            >
                {getSubmitButtonText()}
            </SubmitButton>

            <SubmitButton
                backgroundcolor={QUANTACO_ORANGE}
                bordcolor={QUANTACO_ORANGE}
                size="large"
                type="primary"
                disabled={isApprovedDisabled}
                onClick={() => getApproveButtonClick()}
            >
                {getApproveButtonText()}
            </SubmitButton>
            {(activeUser?.role === "SUPER_ADMIN" ||
                activeUser?.role === "QUANTACO_ADMIN") &&
                (capitalizedStatus == "Submitted" ||
                    capitalizedStatus == "Approved") && <DownloadJournalButton />}
        </SubmitSection>
    );
};
export default SubmitApproveSection;
