import React, { useMemo, useState } from "react";
import { ShiftReportMetricsItem } from "@interfaces/index";
import {
    CustomDataTable,
    LocationContainer,
    ShiftMetricsTableContainer,
    TableTitle,
} from "../../CashupReportStyled";
import { shiftWagesColumnGenerator } from "../../../utils";

interface ShiftReportWageProps {
    wageData: { data: ShiftReportMetricsItem[]; title: string };
    dateRangeCheck?: boolean;
}

const GroupShiftReportWageMetrics: React.FC<ShiftReportWageProps> = ({
    wageData,
    dateRangeCheck,
}) => {
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascend" | "descend" | null>(
        null
    );

    const sortedData = useMemo(() => wageData.data, [wageData]);

    //prepare columns
    const columns = useMemo(
        () => shiftWagesColumnGenerator(sortedColumn, sortDirection),
        [sortedColumn, sortDirection]
    );

    //handle sorting
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedColumn(sorter.columnKey);
        setSortDirection(sorter.order as "ascend" | "descend" | null);
    };

    return (
        <>
            <LocationContainer>
                <TableTitle
                    style={{
                        marginBottom: "15px",
                    }}
                >
                    {`${wageData.title} (Beta)`}
                    {dateRangeCheck && (
                        <span
                            style={{
                                fontWeight: "normal",
                                fontSize: "0.9em",
                                color: "gray",
                                marginLeft: "5px",
                            }}
                        >
                            {`Note: Wage and sales data for today's and future dates are not included.`}
                        </span>
                    )}
                </TableTitle>
                <ShiftMetricsTableContainer>
                    <CustomDataTable
                        dataSource={sortedData}
                        columns={columns}
                        size="small"
                        scroll={{ x: true }}
                        tableLayout="fixed"
                        pagination={false}
                        onChange={handleTableChange}
                    />
                </ShiftMetricsTableContainer>
            </LocationContainer>
        </>
    );
};

export default GroupShiftReportWageMetrics;
