/**
 * Note this component makes the assumption that there will only be a single Gaming CashupID that we subscribe to
 * as the Gaming machines section is hard coded to use only the first / 0 index within the tableData array.
 */

import React, { useCallback, useMemo, useRef } from "react";
import {
    ExtendedVenueItem,
    GamingLocation,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import { usePushCashupMutation } from "Redux/StateSlices/Pusher";
import {
    GameData,
    GamingMachineCount,
    InitDataResponseType,
} from "../../Pages/CashupHome/CashupTabs/POS/@types";
import { TableDataType } from "../POS/POSPanel";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";
import produce from "immer";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import { TableRows } from "V2/Table/TableBody";
import { TableConfigs } from "V2/Table/TableHeader";
import { Table } from "V2/Table/Table";
import set from "lodash/set";
import {
    LocationDetailByCashup,
    parseTimePeriod,
    sortCashupIDS,
    sortTableData,
} from "../../utils/utilities";
import { positiveNumberValidator } from "../../Pages/CashupHome/CashupTabs/POS/utils";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import { cloneDeep } from "lodash";
import { StyledCollapsedPanel } from "Components/StyledCollapsedPanel";
import Title from "antd/lib/typography/Title";
import { Checkbox, Divider } from "antd";
import styled from "styled-components";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { useLoader } from "hooks/loaderProvider";
import { postGamingUpload } from "ApiV2/Helpers/postGamingUpload";
import {
    MODAL_GENERIC_ERROR,
    MODAL_GENERIC_SUCCESS,
} from "Pages/CashupHome/ExcelTable/utils";
import { useContextModal } from "hooks/useModal";
import { useVenueSelection } from "Context/VenueSelectionContextConstants";

const StyledDivider = styled(Divider)`
    margin: 0 0 10px 0;
`;

interface Props extends PanelHOCComponentProps {
    name: string;
    subLocations: GamingLocation[];
    transferLocations: ExtendedLocationItemWithChildren[];
    venueData: ExtendedVenueItem;
    currentTableInFocus?: string;
    panelId?: string;
    submitted: boolean;
    isGamingUploadEnabled?: boolean;
    setGamingDownloadStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const initializeGamingFields = (
    cashup: InitDataResponseType
): InitDataResponseType => {
    const gamingData = cashup.gaming_data;
    return {
        ...cashup,
        gaming_data: {
            jackpot: gamingData?.jackpot ?? 0,
            unclaimed_tickets: gamingData?.unclaimed_tickets ?? 0,
            total_wins: gamingData?.total_wins ?? 0,
            turnover: gamingData?.turnover ?? 0,
            gaming_machine_counts: gamingData.gaming_machine_counts ?? [],
            net_profit: gamingData.net_profit ?? 0,
            tickets_in: gamingData.tickets_in ?? 0,
            p2p: gamingData.p2p ?? 0,
        },
    };
};

const setGamingMachineCountValue = (
    gaming_data: GameData,
    gamingCountsNumber: number,
    property: string,
    updatedValue: number
) => {
    const gamingData = { ...gaming_data };
    if (
        !gamingData.gaming_machine_counts ||
        gamingData.gaming_machine_counts?.length === 0
    ) {
        if (!gamingData.gaming_machine_counts) gamingData.gaming_machine_counts = [];
        for (let i = 0; i <= gamingCountsNumber; i++) {
            gamingData.gaming_machine_counts.push({
                cash_box_read: 0,
                cash_count: 0,
                variance: 0,
            });
        }
        const currentgamingCount = Object.keys(
            gamingData.gaming_machine_counts[0]
        ).reduce<{ [name: string]: number }>((acc, curr) => {
            if (curr === property) {
                acc[curr] = updatedValue;
            } else {
                acc[curr] = 0;
            }
            return acc;
        }, {});
        gamingData.gaming_machine_counts.splice(
            gamingCountsNumber,
            1,
            currentgamingCount as GamingMachineCount
        );
    } else {
        const gamingCountsData = [...gamingData.gaming_machine_counts];
        if (!gamingData.gaming_machine_counts[gamingCountsNumber]) {
            for (
                let i = 0;
                i <= gamingCountsNumber - gamingData.gaming_machine_counts.length;
                i++
            ) {
                gamingCountsData.push({
                    cash_box_read: 0,
                    cash_count: 0,
                    variance: 0,
                });
            }
        }
        const gamingCountData = {
            ...gamingCountsData[gamingCountsNumber],
        };
        gamingCountData[property as keyof GamingMachineCount] = updatedValue;
        gamingCountsData.splice(gamingCountsNumber, 1, gamingCountData);
        gamingData.gaming_machine_counts = gamingCountsData;
    }
    return gamingData;
};

const processData = (data: any) => (isNaN(Number(data)) ? 0 : Number(data));

export const GamingPanel: React.FC<Props> = ({
    subLocations,
    venueData,
    onClickHandler,
    currentTableInFocus,
    panelId,
    submitted,
    isGamingUploadEnabled,
    setGamingDownloadStatus,
}) => {
    const [pushTableState] = usePushCashupMutation();
    const locationDetailByCashupId = React.useMemo(() => {
        return subLocations.reduce<LocationDetailByCashup>(
            (result, { cashups, name, location_id }) => {
                cashups.forEach(
                    ({ cashup_id, time_period: timePeriod }) =>
                        (result[cashup_id] = { name, timePeriod, location_id })
                );
                return result;
            },
            {}
        );
    }, [subLocations]);

    const [tableData, setTableData] = React.useState<InitDataResponseType[]>([]);
    const inputRefGaming = useRef<null | HTMLInputElement>(null);
    const inputRefGamingMachine = useRef<null | HTMLInputElement>(null);
    const { venueId, formattedDate } = useVenueSelection();
    const { withLoader } = useLoader();
    const { openModal, closeModal } = useContextModal();
    const handleFileUpload = useCallback(
        (inputRef: React.MutableRefObject<HTMLInputElement | null>) => {
            if (inputRef?.current) {
                inputRef.current.click();
            }
        },
        []
    );
    const updateTableData = (cashup: InitDataResponseType) =>
        setTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);
                    draft.sort((cashupA, cashupB) =>
                        sortTableData(cashupA, cashupB, locationDetailByCashupId)
                    );
                }
            });

            return updatedTableData;
        });

    const cashupIds = React.useMemo(
        () =>
            subLocations
                .flatMap(({ cashups }) => cashups.map(({ cashup_id }) => cashup_id))
                .sort((cashupA_ID, cashupB_ID) =>
                    sortCashupIDS(cashupA_ID, cashupB_ID, locationDetailByCashupId)
                ),
        [subLocations, locationDetailByCashupId]
    );

    const { isLoading } = useCashupRealtime({
        cashupIds,
        onCashupInitialized: updateTableData,
        onCashupUpdated: updateTableData,
    });

    const handleCashupChanged = useCallback(
        (updatedCashup: InitDataResponseType) =>
            pushTableState(updatedCashup).unwrap(),
        [pushTableState]
    );

    /**
     * Method invoked on tableData change to sync with backend
     * @param gamingDataEdit
     * @param currentTableState
     */
    const onRowDataChange = React.useCallback(
        (
                gamingDataEdit?: boolean,
                GamingMachineDataEdit?: boolean,
                cancelChange?: boolean
            ) =>
            (rowIndex: number, tableData: TableDataType[]) => {
                const cashupData = {
                    ...tableData[rowIndex],
                    gaming_data: {
                        ...tableData[rowIndex].gaming_data,
                    },
                };
                if (gamingDataEdit) {
                    Object.assign(cashupData, { user_edited_gaming_data: true });
                } else if (GamingMachineDataEdit) {
                    Object.assign(cashupData, { gaming_edited_cash_box_read: true });
                } else if (cancelChange === false) {
                    Object.assign(cashupData, {
                        user_edited_gaming_data: false,
                        gaming_edited_cash_box_read: false,
                    });
                }
                handleCashupChanged(cashupData);
            },
        [handleCashupChanged]
    );

    const uploadFile = useCallback(async (file: File, cashup_id: string) => {
        const cashupGamingStatus = {
            cashup_id: cashup_id,
            user_edited_gaming_data: false,
            gaming_edited_cash_box_read: false,
        };
        const formData = new FormData();
        formData.append("file", file);
        formData.append("venue_id", venueId);
        formData.append("shift_date", formattedDate);
        formData.append("cashup_gaming_status", JSON.stringify(cashupGamingStatus));
        withLoader(async () => {
            await postGamingUpload(formData)
                .then(() => {
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "PDF file upload successfully"
                    );
                    setGamingDownloadStatus(true);
                })
                .catch((errorResponse) => {
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        undefined,
                        <>
                            <h3>PDF file upload failed.</h3>
                            <p>{Object.values(errorResponse)[0]}</p>
                        </>
                    );
                });
        });
    }, []);
    const handleFileSelection =
        (rowindex: number, tableData: InitDataResponseType[], cashup_id: string) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files && event.target.files[0];
            if (file && file.type === "application/pdf") {
                uploadFile(file, cashup_id);
                event.target.value = "";
            } else {
                MODAL_GENERIC_ERROR(
                    openModal,
                    closeModal,
                    undefined,
                    <>
                        <h3>Please select a PDF file</h3>
                        <p>Gaming upload only accept PDF files</p>
                    </>,
                    undefined
                );
                event.target.value = "";
            }
        };

    const dataSources: TableRows[] = useMemo(
        () =>
            tableData.map((cashup, rowIndex) => {
                const { cashup_id, gaming_data, user_edited_gaming_data } = cashup;

                return [
                    {
                        value: locationDetailByCashupId[cashup_id].name,
                        readOnly: true,
                        subValue: parseTimePeriod(
                            locationDetailByCashupId[cashup_id].timePeriod
                        ),
                        manuallyChangedData: user_edited_gaming_data,
                        editPopover: (
                            <>
                                <StyledDivider />
                                {submitted ? (
                                    <span>Gaming data has been manually edited</span>
                                ) : (
                                    <span>
                                        Gaming data has been manually edited. To
                                        override this edit, tick the checkbox and
                                        proceed to upload gaming report again.
                                    </span>
                                )}
                                <Checkbox
                                    style={{ float: "right" }}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            handleFileUpload(inputRefGaming);
                                        }
                                    }}
                                    disabled={submitted}
                                >
                                    Yes
                                </Checkbox>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    accept="application/pdf"
                                    ref={inputRefGaming}
                                    onChange={(e) => {
                                        handleFileSelection(
                                            rowIndex,
                                            tableData,
                                            cashup_id
                                        )(e);
                                    }}
                                />
                            </>
                        ),
                    },
                    {
                        value: gaming_data?.turnover ?? 0,
                        readOnly: submitted,
                        propertyPath: "gaming_data.turnover",
                        validator: positiveNumberValidator,
                    },
                    {
                        value: gaming_data?.total_wins ?? 0,
                        readOnly: submitted,
                        propertyPath: "gaming_data.total_wins",
                        validator: positiveNumberValidator,
                    },
                    {
                        value: gaming_data?.jackpot ?? 0,
                        readOnly: submitted,
                        propertyPath: "gaming_data.jackpot",
                        validator: positiveNumberValidator,
                    },

                    {
                        value: gaming_data?.unclaimed_tickets ?? 0,
                        readOnly: submitted,
                        propertyPath: "gaming_data.unclaimed_tickets",
                    },

                    {
                        // p2p
                        value: gaming_data?.p2p ?? 0,
                        readOnly: submitted,
                        propertyPath: "gaming_data.p2p",
                        validator: positiveNumberValidator,
                    },
                ];
            }),
        [tableData, submitted]
    );

    const tableConfigs: TableConfigs = [
        { columnTitle: "" },
        { columnTitle: "Turnover" },
        { columnTitle: "Total Wins" },
        { columnTitle: "Jackpots" },
        { columnTitle: "Unclaimed Movements" },
        // { columnTitle: "Cash Box" },
        { columnTitle: "P2P" },
    ];

    const gamingMachineCountsColumn = useMemo(() => {
        const result: { columnTitle: string }[] = [];
        if (!venueData) {
            throw new Error("Venue not found");
        } else if (venueData.gaming_machine_counts === 0) {
            result.push({
                columnTitle: "Total Machine Count",
            });
            return result;
        } else {
            for (let i = 0; i < venueData.gaming_machine_counts; i++) {
                result.push({ columnTitle: `#${i + 1}` });
            }
        }
        return result;
    }, [venueData]);

    const gamingMachineCountVariance = useCallback(
        (rowIndex: number) => {
            if (tableData[0]) {
                const cashBoxRead = tableData[0].gaming_data?.gaming_machine_counts
                    ? tableData[0].gaming_data?.gaming_machine_counts[rowIndex]
                          ?.cash_box_read
                    : 0;
                const cashCount = tableData[0].gaming_data?.gaming_machine_counts
                    ? tableData[0].gaming_data?.gaming_machine_counts[rowIndex]
                          ?.cash_count
                    : 0;
                return processData(cashCount) - processData(cashBoxRead);
            }
            return 0;
        },
        [tableData]
    );
    const onGamingMachineCountChange = useCallback(
        (
            tableData: InitDataResponseType[],
            tableRow: number,
            GamingMachineNumber: number,
            property: string,
            data: number,
            isCashboxRead?: boolean
        ) => {
            const tableDataDeepClone = cloneDeep(tableData);
            tableDataDeepClone[tableRow].gaming_data = setGamingMachineCountValue(
                tableDataDeepClone[tableRow].gaming_data,
                GamingMachineNumber,
                property,
                data
            );
            isCashboxRead && isGamingUploadEnabled
                ? onRowDataChange(undefined, true)(tableRow, tableDataDeepClone)
                : onRowDataChange()(tableRow, tableDataDeepClone);
        },
        [isGamingUploadEnabled]
    );
    const dataSourceForGamingMachineCounts: TableRows[] = useMemo(() => {
        const result: TableRows[] = [];
        if (!venueData) {
            throw new Error("Venue not found");
        } else if (venueData.gaming_machine_counts === 0) {
            const cashCountErrorFlag =
                processData(
                    tableData[0]?.gaming_data?.gaming_machine_counts
                        ? tableData[0]?.gaming_data?.gaming_machine_counts[0]
                              ?.cash_box_read
                        : 0
                ) !== 0 &&
                processData(
                    tableData[0]?.gaming_data?.gaming_machine_counts
                        ? tableData[0]?.gaming_data?.gaming_machine_counts[0]
                              ?.cash_count
                        : 0
                ) === 0;
            result.push([
                {
                    value: "Total Machine Count",
                    readOnly: true,
                    manuallyChangedData:
                        tableData[0]?.gaming_edited_cash_box_read ?? false,
                    editPopover: (
                        <>
                            <StyledDivider />
                            {submitted ? (
                                <span>
                                    Cash box read data has been manually edited
                                </span>
                            ) : (
                                <span>
                                    Gaming data has been manually edited. To override
                                    this edit, tick the checkbox and proceed to
                                    upload gaming report again.
                                </span>
                            )}
                            <Checkbox
                                style={{ float: "right" }}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        handleFileUpload(inputRefGamingMachine);
                                    }
                                }}
                                disabled={submitted}
                            >
                                Yes
                            </Checkbox>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                accept="application/pdf"
                                ref={inputRefGamingMachine}
                                onChange={handleFileSelection(
                                    0,
                                    tableData,
                                    tableData[0]?.cashup_id ?? ""
                                )}
                            />
                        </>
                    ),
                },
                {
                    value: processData(
                        tableData[0]?.gaming_data?.gaming_machine_counts
                            ? tableData[0]?.gaming_data?.gaming_machine_counts[0]
                                  ?.cash_box_read
                            : 0
                    ),
                    onSubmit: (data: number) =>
                        onGamingMachineCountChange(
                            tableData,
                            0,
                            0,
                            "cash_box_read",
                            data,
                            true
                        ),
                    readOnly: submitted,
                    validator: positiveNumberValidator,
                },
                {
                    value: processData(
                        tableData[0]?.gaming_data?.gaming_machine_counts
                            ? tableData[0]?.gaming_data?.gaming_machine_counts[0]
                                  ?.cash_count
                            : 0
                    ),
                    onSubmit: (data: number) =>
                        onGamingMachineCountChange(
                            tableData,
                            0,
                            0,
                            "cash_count",
                            data
                        ),
                    readOnly: submitted,
                    errorHighlighting: cashCountErrorFlag,
                    validator: positiveNumberValidator,
                },
                {
                    value: gamingMachineCountVariance(0),
                    readOnly: true,
                    onSubmit: (data: number) =>
                        onGamingMachineCountChange(
                            tableData,
                            0,
                            0,
                            "variance",
                            data
                        ),
                },
            ]);
        } else {
            gamingMachineCountsColumn?.forEach((currGamingCount, index) => {
                const cashCountErrorFlag =
                    processData(
                        tableData[0]?.gaming_data?.gaming_machine_counts
                            ? tableData[0]?.gaming_data?.gaming_machine_counts[index]
                                  ?.cash_box_read
                            : 0
                    ) !== 0 &&
                    processData(
                        tableData[0]?.gaming_data?.gaming_machine_counts
                            ? tableData[0]?.gaming_data?.gaming_machine_counts[index]
                                  ?.cash_count
                            : 0
                    ) === 0;
                index === 0
                    ? result.push([
                          {
                              value: currGamingCount.columnTitle,
                              readOnly: true,
                              manuallyChangedData:
                                  tableData[0]?.gaming_edited_cash_box_read ?? false,
                              editPopover: (
                                  <>
                                      <StyledDivider />
                                      {submitted ? (
                                          <span>
                                              Cash box read data has been manually
                                              edited
                                          </span>
                                      ) : (
                                          <span>
                                              Gaming data has been manually edited.
                                              To override this edit, tick the
                                              checkbox and proceed to upload gaming
                                              report again.
                                          </span>
                                      )}
                                      <Checkbox
                                          style={{ float: "right" }}
                                          onChange={(e) => {
                                              if (e.target.checked) {
                                                  if (e.target.checked) {
                                                      handleFileUpload(
                                                          inputRefGamingMachine
                                                      );
                                                  }
                                              }
                                          }}
                                          disabled={submitted}
                                      >
                                          Yes
                                      </Checkbox>
                                      <input
                                          type="file"
                                          style={{ display: "none" }}
                                          accept="application/pdf"
                                          ref={inputRefGamingMachine}
                                          onChange={handleFileSelection(
                                              0,
                                              tableData,
                                              tableData[0]?.cashup_id ?? ""
                                          )}
                                      />
                                  </>
                              ),
                          },
                          {
                              value: processData(
                                  tableData[0]?.gaming_data?.gaming_machine_counts
                                      ? tableData[0].gaming_data
                                            ?.gaming_machine_counts[index]
                                            ?.cash_box_read
                                      : 0
                              ),
                              onSubmit: (data: number) =>
                                  onGamingMachineCountChange(
                                      tableData,
                                      0,
                                      index,
                                      "cash_box_read",
                                      data,
                                      true
                                  ),
                              readOnly: submitted,
                              validator: positiveNumberValidator,
                          },
                          {
                              value: processData(
                                  tableData[0]?.gaming_data?.gaming_machine_counts //logic problem here
                                      ? tableData[0].gaming_data
                                            ?.gaming_machine_counts[index]
                                            ?.cash_count
                                      : 0
                              ),
                              onSubmit: (data: number) =>
                                  onGamingMachineCountChange(
                                      tableData,
                                      0,
                                      index,
                                      "cash_count",
                                      data
                                  ),
                              readOnly: submitted,
                              errorHighlighting: cashCountErrorFlag,
                              validator: positiveNumberValidator,
                          },
                          {
                              value: gamingMachineCountVariance(index),
                              readOnly: true,
                              onSubmit: (data: number) =>
                                  onGamingMachineCountChange(
                                      tableData,
                                      0,
                                      index,
                                      "variance",
                                      data
                                  ),
                          },
                      ])
                    : result.push([
                          {
                              value: currGamingCount.columnTitle,
                              readOnly: true,
                          },
                          {
                              value: processData(
                                  tableData[0]?.gaming_data?.gaming_machine_counts
                                      ? tableData[0].gaming_data
                                            ?.gaming_machine_counts[index]
                                            ?.cash_box_read
                                      : 0
                              ),
                              onSubmit: (data: number) =>
                                  onGamingMachineCountChange(
                                      tableData,
                                      0,
                                      index,
                                      "cash_box_read",
                                      data
                                  ),
                              readOnly: submitted,
                              validator: positiveNumberValidator,
                          },
                          {
                              value: processData(
                                  tableData[0]?.gaming_data?.gaming_machine_counts //logic problem here
                                      ? tableData[0].gaming_data
                                            ?.gaming_machine_counts[index]
                                            ?.cash_count
                                      : 0
                              ),
                              onSubmit: (data: number) =>
                                  onGamingMachineCountChange(
                                      tableData,
                                      0,
                                      index,
                                      "cash_count",
                                      data
                                  ),
                              readOnly: submitted,
                              errorHighlighting: cashCountErrorFlag,
                              validator: positiveNumberValidator,
                          },
                          {
                              value: gamingMachineCountVariance(index),
                              readOnly: true,
                              onSubmit: (data: number) =>
                                  onGamingMachineCountChange(
                                      tableData,
                                      0,
                                      index,
                                      "variance",
                                      data
                                  ),
                          },
                      ]);
            });
        }
        return result;
    }, [gamingMachineCountsColumn, venueData, tableData, submitted]);

    const tableConfigsForGamingMachineCounts: TableConfigs = [
        { columnTitle: "Machine Clearance" },
        { columnTitle: "Clearance" },
        { columnTitle: "Cash Count" },
        { columnTitle: "Variance" },
    ];
    if (isLoading || tableData.length === 0) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "-35px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"small"}
            />
        );
    }

    return (
        <>
            <Table
                onCellValueChanged={({ row, updatedValue, propertyPath }) => {
                    const updatedCashup = produce(
                        initializeGamingFields(tableData[row]),
                        (draft) => {
                            set(draft, propertyPath, updatedValue);
                            if (isGamingUploadEnabled) {
                                const is7000Report =
                                    venueData.gaming_report_type ===
                                    "gaming_s7000_report_summary";
                                if (is7000Report) {
                                    // Disable error flag for unclaimed tickets
                                    const isUnclaimedTickets =
                                        propertyPath ===
                                        "gaming_data.unclaimed_tickets";
                                    set(
                                        draft,
                                        "user_edited_gaming_data",
                                        !isUnclaimedTickets
                                    );
                                } else {
                                    set(draft, "user_edited_gaming_data", true);
                                }
                            }
                        }
                    );
                    handleCashupChanged(updatedCashup);
                }}
                dataSources={dataSources}
                tableConfigs={tableConfigs}
                onClickHandler={() => onClickHandler(1)}
                isInFocus={currentTableInFocus === `${panelId}-1`}
                evenDistribution
            />
            <StyledCollapsedPanel
                header={
                    <Title level={3} style={{ margin: "0px" }}>
                        Machine Clearance
                    </Title>
                }
                style={{ paddingTop: "12px", paddingBottom: "24px" }}
                key={"13213213123"}
            />
            <Table
                dataSources={dataSourceForGamingMachineCounts}
                tableConfigs={tableConfigsForGamingMachineCounts}
                onClickHandler={() => onClickHandler(2)}
                isInFocus={currentTableInFocus === `${panelId}-2`}
                evenDistribution
            />
        </>
    );
};
