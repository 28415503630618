import React, { useCallback, useMemo } from "react";
import { usePushCashupMutation } from "Redux/StateSlices/Pusher";
import { InitDataResponseType } from "../../Pages/CashupHome/CashupTabs/POS/@types";
import produce from "immer";
import { CashupSubLocationItem } from "Redux/StateSlices/GroupData/VenuesAPI";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import {
    countTransactions,
    parseTimePeriod,
    sortCashupIDS,
    sortTableData,
    transformATMCashupsToBankTechDeposit,
    transformLocationsToLocationDetailByCashup,
} from "../../utils/utilities";
import { TableRows } from "V2/Table/TableBody";
import set from "lodash/set";
import { Table } from "V2/Table/Table";
import { TableConfigs } from "V2/Table/TableHeader";
import cloneDeep from "lodash/cloneDeep";
import { DenominationsForm } from "../../Pages/CashupHome/ExcelTable/DenominationsForm";
import { AntDFormStateWithoutSplit, KeyValuePair } from "@types";
import { TableDataType } from "../POS/POSPanel";
import { onTransferTransactionSubmission } from "../Gaming/utils";
import { TransactionsFormTransferOnly } from "../../Pages/CashupHome/ExcelTable/TransactionsFormTransferOnly";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { formatDollarField } from "@Constants";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { DEFAULT_DEBOUNCE_TIME } from "@Constants/debounce";
import { positiveNumberValidator } from "../../Pages/CashupHome/CashupTabs/POS/utils";
import { StyledCollapsedPanel } from "Components/StyledCollapsedPanel";
import Title from "antd/es/typography/Title";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";

interface Props extends PanelHOCComponentProps {
    name: string;
    cashupIds: string[];
    subLocations: CashupSubLocationItem[];
    locations: ExtendedLocationItem[];
    transferLocations: ExtendedLocationItemWithChildren[];
    submitted: boolean;
    currentTableInFocus?: string;
    panelId?: string;
}

const initializeAtmField = (
    cashup: InitDataResponseType,
    isTABDeposit?: boolean
): InitDataResponseType => {
    const atmData = cashup.atm_data;
    if (isTABDeposit) {
        return {
            ...cashup,
            atm_data: {
                refills: atmData?.refills ?? 0,
                withdrawls: atmData?.withdrawls ?? 0,
                bank_tech_deposits: {
                    bank_tech_deposits:
                        atmData?.bank_tech_deposits?.bank_tech_deposits ?? 0,
                    cash_count: atmData?.bank_tech_deposits?.cash_count ?? 0,
                    variance: atmData?.bank_tech_deposits?.variance ?? 0,
                },
            },
        };
    } else {
        return {
            ...cashup,
            atm_data: {
                refills: atmData?.refills ?? 0,
                refillsDenominations: atmData?.refillsDenominations ?? [],
                withdrawls: atmData?.withdrawls ?? 0,
                withdrawalsDenominations: atmData?.withdrawalsDenominations ?? [],
            },
        };
    }
};

export const ATMPanel: React.FC<Props> = ({
    cashupIds,
    subLocations,
    locations,
    transferLocations,
    onClickHandler,
    currentTableInFocus,
    panelId,
    submitted,
}) => {
    const [updateCashup] = usePushCashupMutation();
    const [tableData, setTableData] = React.useState<InitDataResponseType[]>([]);
    const updateTableData = (cashup: InitDataResponseType) =>
        setTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);

                    draft.sort((cashupA, cashupB) =>
                        sortTableData(cashupA, cashupB, locationDetailByCashupId)
                    );
                }
            });

            return updatedTableData;
        });

    const locationDetailByCashupId = useMemo(
        () => transformLocationsToLocationDetailByCashup(subLocations),
        [subLocations]
    );

    const sortedCashupIDS = useMemo(
        () =>
            cloneDeep(cashupIds).sort((cashupA_ID, cashupB_ID) =>
                sortCashupIDS(cashupA_ID, cashupB_ID, locationDetailByCashupId)
            ),
        [cashupIds, locationDetailByCashupId]
    );

    const { isLoading } = useCashupRealtime({
        cashupIds: sortedCashupIDS,
        onCashupInitialized: updateTableData,
        onCashupUpdated: updateTableData,
    });

    const handleCashupChanged = useCallback(
        (updatedCashup: InitDataResponseType) =>
            updateCashup(updatedCashup).unwrap(),
        [updateCashup]
    );

    /**
     * Method invoked on tableData change to sync with backend
     * @param rowIndex
     * @param currentTableState
     */
    const onRowDataChange = React.useCallback(
        (rowIndex: number, tableData: TableDataType[]) => {
            //  setTableData(tableData);

            handleCashupChanged(tableData[rowIndex]);
        },
        [handleCashupChanged]
    );

    const updateDenominationsState = useCallback(
        (type: "WITHDRAWALS" | "REFILLS", rowIndex: number) =>
            (data: KeyValuePair) => {
                const tableDataDeepCopy = cloneDeep(tableData);

                const denominations = [];
                let denominationsSum = 0;

                for (const [key, value] of Object.entries(data)) {
                    if (value !== undefined) {
                        const dollarValue = Number(
                            typeof value === "number" ? value.toString() : value
                        );
                        const temp: { [name: string]: number } = {};
                        temp[key] = dollarValue;
                        denominations.push(temp);
                        denominationsSum += dollarValue;
                    }
                }

                if (type === "WITHDRAWALS") {
                    tableDataDeepCopy[rowIndex].atm_data = {
                        ...tableDataDeepCopy[rowIndex]?.atm_data,
                        withdrawalsDenominations: denominations,
                        withdrawls: denominationsSum,
                    };
                } else if (type === "REFILLS") {
                    tableDataDeepCopy[rowIndex].atm_data = {
                        ...tableDataDeepCopy[rowIndex]?.atm_data,
                        refillsDenominations: denominations,
                        refills: denominationsSum,
                    };
                }

                onRowDataChange(rowIndex, tableDataDeepCopy);
            },
        [tableData, onRowDataChange]
    );
    const onTransferTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithoutSplit[], rowIndex: number) =>
            onTransferTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    const bankTechDepositByCashupid = useMemo(
        () => transformATMCashupsToBankTechDeposit(subLocations),
        [subLocations]
    );
    const isBankTechDepositOn = useMemo(
        () =>
            Object.values(bankTechDepositByCashupid).reduce(
                (result, curr) => result || curr,
                false
            ),
        [bankTechDepositByCashupid]
    );

    const dataSources: TableRows[] = tableData.map((cashup, rowIndex) => {
        const {
            cashup_id,
            atm_data,
            cash_count,
            transfer_transactions,
            status,
            transfer_transactions_to,
        } = cashup;
        const cashCountErrorFlag = !!atm_data?.refills && !cash_count.actual;
        return [
            {
                value: locationDetailByCashupId[cashup_id].name,
                readOnly: true,
                subValue: parseTimePeriod(
                    locationDetailByCashupId[cashup_id].timePeriod
                ),
            },
            {
                value: cash_count.opening_balance ?? 0,
                readOnly: true,
            },
            {
                value: atm_data?.withdrawls ?? 0,
                editModal: (closeModal) => (
                    <DenominationsForm
                        row={rowIndex}
                        key={
                            "ATM_Withdrawals_" +
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onSubmit={updateDenominationsState("WITHDRAWALS", rowIndex)}
                        onModalClose={closeModal}
                        initialValues={atm_data?.withdrawalsDenominations ?? []}
                        disabled={submitted}
                    />
                ),
                modalTitle: "Withdrawals",
                hideEditModalFooter: true,
            },
            {
                value: atm_data?.refills ?? 0,
                editModal: (closeModal) => (
                    <DenominationsForm
                        row={rowIndex}
                        key={
                            "ATM_refills_" +
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onSubmit={updateDenominationsState("REFILLS", rowIndex)}
                        onModalClose={closeModal}
                        initialValues={atm_data?.refillsDenominations ?? []}
                        disabled={submitted}
                    />
                ),
                modalTitle: "Refills",
                hideEditModalFooter: true,
            },
            {
                value: tableData[rowIndex]
                    ? countTransactions([
                          transfer_transactions ?? [],
                          transfer_transactions_to ?? [],
                      ])
                    : "",
                editModal: (closeModal) => (
                    <TransactionsFormTransferOnly
                        key={
                            "ATM_" + locationDetailByCashupId[cashup_id].location_id
                        }
                        locations={locations}
                        onTransferTransactionSubmission={(data) =>
                            onTransferTransactionSubmissionDebounce(data, rowIndex)
                        }
                        TransferTransactions={transfer_transactions ?? []}
                        TransferTransactionsReadOnly={transfer_transactions_to ?? []}
                        hierarchicalLocations={transferLocations}
                        currentLocationID={
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onModalClose={closeModal}
                        disabled={submitted}
                    />
                ),
                modalTitle: "Transactions",
                hideEditModalFooter: true,
            },
            {
                value: cash_count.actual ?? "$0",
                applyDollarFormatting: true,
                isExplicitlyNotTitleCell: true,
                propertyPath: "cash_count.actual",
                nanInsteadOfZeroOut: true,
                readOnly: submitted,
                validator: positiveNumberValidator,
                errorHighlighting: cashCountErrorFlag,
            },
            {
                value: formatDollarField(cash_count.expected_cash ?? 0),
                readOnly: true,
                statusField: () =>
                    cash_count.expected_cash === null ? status : null,
            },
            {
                value:
                    typeof cash_count.cash_variance !== "string"
                        ? formatDollarField(cash_count.cash_variance ?? 0)
                        : cash_count.cash_variance,
                readOnly: true,
                statusField: () => status ?? null,
            },
        ];
    });

    const tableConfigs: TableConfigs = [
        { columnTitle: "ATM" },
        { columnTitle: "Opening balance" },
        { columnTitle: "Withdrawals" },
        { columnTitle: "Refills" },
        { columnTitle: "Transactions" },
        { columnTitle: "Cash count", separateColumn: true },
        { columnTitle: "Expected" },
        { columnTitle: "Variance" },
    ];

    const dataSourceForTABDeposit = useMemo(() => {
        const result: TableRows[] = [];
        tableData.forEach((cashup) => {
            const { cashup_id, atm_data } = cashup;
            if (bankTechDepositByCashupid[cashup_id]) {
                result.push([
                    {
                        value: locationDetailByCashupId[cashup_id].name,
                        readOnly: true,
                        subValue: parseTimePeriod(
                            locationDetailByCashupId[cashup_id].timePeriod
                        ),
                    },
                    {
                        value: atm_data?.bank_tech_deposits?.bank_tech_deposits ?? 0,
                        propertyPath:
                            "atm_data.bank_tech_deposits.bank_tech_deposits",
                        readOnly: submitted,
                        validator: positiveNumberValidator,
                    },
                    {
                        value: atm_data?.bank_tech_deposits?.cash_count ?? 0,
                        propertyPath: "atm_data.bank_tech_deposits.cash_count",
                        readOnly: submitted,
                        validator: positiveNumberValidator,
                    },
                    {
                        value: atm_data?.bank_tech_deposits?.variance ?? 0,
                        readOnly: true,
                    },
                ]);
            }
        });
        return result;
    }, [tableData, locationDetailByCashupId, bankTechDepositByCashupid, submitted]);

    const tableConfigsForTABDeposit = [
        { columnTitle: "Name" },
        { columnTitle: "TAB Deposit Read" },
        { columnTitle: "Cash Count" },
        { columnTitle: "Variance" },
    ];

    if (isLoading || cashupIds.length !== tableData.length) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "-35px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"small"}
            />
        );
    }
    return (
        <>
            <Table
                onCellValueChanged={({ row, updatedValue, propertyPath }) => {
                    const updatedCashup = produce(
                        initializeAtmField(
                            tableData[row],
                            bankTechDepositByCashupid[tableData[row].cashup_id]
                        ),
                        (draft) => {
                            set(draft, propertyPath, updatedValue);
                        }
                    );
                    handleCashupChanged(updatedCashup);
                }}
                dataSources={dataSources}
                tableConfigs={tableConfigs}
                onClickHandler={() => onClickHandler(1)}
                isInFocus={currentTableInFocus === `${panelId}-1`}
            />
            {isBankTechDepositOn ? (
                <>
                    <StyledCollapsedPanel
                        header={<Title level={3} style={{ margin: "0px" }}></Title>}
                        style={{ paddingTop: "12px", paddingBottom: "24px" }}
                        key={"1"}
                    />
                    <Table
                        onCellValueChanged={({
                            row,
                            updatedValue,
                            propertyPath,
                        }) => {
                            const updatedCashup = produce(
                                initializeAtmField(
                                    tableData[row],
                                    bankTechDepositByCashupid[
                                        tableData[row].cashup_id
                                    ]
                                ),
                                (draft) => {
                                    set(draft, propertyPath, updatedValue);
                                }
                            );
                            handleCashupChanged(updatedCashup);
                        }}
                        dataSources={dataSourceForTABDeposit}
                        tableConfigs={tableConfigsForTABDeposit}
                        onClickHandler={() => onClickHandler(2)}
                        isInFocus={currentTableInFocus === `${panelId}-2`}
                        evenDistribution
                    />
                </>
            ) : null}
        </>
    );
};
