import { createContext, ReactNode, useContext } from "react";
import { User } from "../Types/User";

export interface UserContextType {
    user?: User;
    setUser: (user: undefined | User) => void;
}

// Create the context
export const UserContext = createContext<UserContextType>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUser: () => {},
    user: undefined,
});

// Custom hook to use the user context
export const useUser = () => useContext(UserContext);

export interface UserProviderProps {
    children: ReactNode; // Use ReactNode for children prop
}
