import { formatDollarField } from "@Constants";
import { SplitErrorType } from "@types";
import lastItemInArray from "lodash/last";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";

import cloneDeep from "lodash/cloneDeep";
import { TableDataType } from "V2/POS/POSPanel";
import {
    CashupSubLocationItem,
    SubSafeLocation,
    timePeriodValues,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import { GST_DECIMAL } from "../@Constants";
import { FieldData } from "../@interfaces";
import { KeyValuePair, TransformedKeyValueFields } from "../@types";
import {
    CashupTextStatus,
    EftposCountTransactionInit,
    InitDataResponseType,
} from "../Pages/CashupHome/CashupTabs/POS/@types";
import { checkForSublocations } from "../Pages/CashupHome/ExcelTable/TransactionForms/TransferModalFormV2";
import { ToolTips } from "../tooltip";
import { getAuthTokens } from "./auth0/auth0API";
import dayjs from "dayjs";

export const splitImbalanceCheck = ({
    statusField,
    tenderTotal,
    salesTotal,
}: {
    statusField: CashupTextStatus;
    tenderTotal: number;
    salesTotal: number;
}): SplitErrorType => {
    if (statusField) {
        if (statusField === "calculating") return "NONE";
    }
    if (tenderTotal !== undefined && salesTotal != undefined) {
        const tenderClassDiff = formatDollarField(tenderTotal - salesTotal);
        if (tenderClassDiff > 0) {
            return "CLASS";
        } else if (tenderClassDiff < 0) {
            return "TENDER";
        } else if (tenderClassDiff == 0) {
            return "NONE";
        }
    }
    return "NONE";
};

// export const statusHelper = ({
//     statusField,
//     classesAggValue,
//     tenderAggValue,
//     salesTotalValue,
//     splitErrorType,
// }: {
//     statusField: CashupTextStatus;
//     classesAggValue?: number; // TODO Make optional & use this method on the TabAndKenoPanel
//     tenderAggValue: number;
//     salesTotalValue: number;
//     splitErrorType: SplitErrorType;
// }) => {
//     if (statusField) {
//         if (statusField === "calculating") return statusField;

//         let info = "";
//         if (
//             statusField.toLowerCase() ===
//             "Invalid Or No Balances In Cashup".toLowerCase()
//         ) {
//             const tenderDiff = formatDollarField(salesTotalValue - tenderAggValue);
//             if (splitErrorType === "TENDER" || splitErrorType === "BOTH") {
//                 info += `\n - Tender is off by $${tenderDiff}`;
//             }

//             if (
//                 classesAggValue !== undefined &&
//                 (splitErrorType === "CLASS" || splitErrorType === "BOTH")
//             ) {
//                 const classDiff = formatDollarField(
//                     salesTotalValue - classesAggValue
//                 );

//                 info += `\n - Class is off by $${classDiff}`;
//             }
//         }

//         return statusField + info;
//     }
//     return null;
// };

export const statusHelper = ({ statusField }: { statusField: CashupTextStatus }) => {
    if (statusField) {
        if (statusField === "calculating") return statusField;
        let info = "";
        if (
            statusField.toLowerCase() ===
            "Invalid Or No Balances In Cashup".toLowerCase()
        ) {
            info += `\n - Payment Form tender split not balanced`;
        }

        return statusField + info;
    }
    return null;
};

export type LocationDetailByCashup = {
    [key: string]: {
        name: string;
        timePeriod: timePeriodValues;
        location_id: string;
    };
};
export type LocationDetailByCashupWithShiftType = {
    [key: string]: {
        name: string;
        timePeriod: timePeriodValues;
        location_id: string;
        shiftType: number;
    };
};

export const transformLocationsToLocationDetailByCashup = (
    locations: CashupSubLocationItem[]
): LocationDetailByCashup =>
    locations.reduce<LocationDetailByCashup>(
        (result, { cashups, name, location_id }) => {
            cashups.forEach(
                ({ cashup_id, time_period: timePeriod }) =>
                    (result[cashup_id] = { name, timePeriod, location_id })
            );
            return result;
        },
        {}
    );
export const transformLocationsToLocationDetailByCashupV2 = (
    locations: CashupSubLocationItem[]
): LocationDetailByCashup =>
    locations.reduce<LocationDetailByCashup>(
        (result, { cashups, name, location_id }) => {
            cashups.forEach(
                ({ cashup_id, time_period: timePeriod }) =>
                    (result[cashup_id] = { name, timePeriod, location_id })
            );
            return result;
        },
        {}
    );

export const transformSafeLocationsToSafeLocationDetailByCashup = (
    locations: SubSafeLocation[]
): LocationDetailByCashupWithShiftType =>
    locations.reduce<LocationDetailByCashupWithShiftType>(
        (result, { cashups, name, location_id }) => {
            cashups.forEach(
                ({ cashup_id, time_period: timePeriod, shift_type: shiftType }) =>
                    (result[cashup_id] = {
                        name,
                        timePeriod,
                        location_id,
                        shiftType,
                    })
            );
            return result;
        },
        {}
    );

export const transformATMCashupsToBankTechDeposit = (
    locations: CashupSubLocationItem[]
): { [cashid: string]: boolean } =>
    locations.reduce((result, { cashups, bank_tech_deposit }) => {
        cashups.forEach(({ cashup_id }) =>
            Object.assign(result, { [cashup_id]: !!bank_tech_deposit })
        );
        return result;
    }, {});
export const clearLastFormFieldIfEmpty = (
    currentForm: any[],
    isTransfer = false,
    isDeposits = false,
    isEftposCount = false
) => {
    const currentFormClone = cloneDeep(currentForm);
    const lastITEM = lastItemInArray(currentForm);

    if (lastITEM === undefined) {
        // TODO Add Logging of this error
        // This should never happen
        return [];
    }
    const formArrayLength = currentForm.length - 1;

    const formDataLength = lastITEM.formData.length;

    if (formDataLength === 0) {
        currentFormClone.splice(formArrayLength, 1);
    } else if (isTransfer && formDataLength <= 1) {
        // This exception for Transfer transactions is because by default Transfer transaction have the "From" field pre-populated.
        currentFormClone.splice(formArrayLength, 1);
    } else if ((isDeposits || isEftposCount) && formDataLength <= 2) {
        // This exception for Deposits (In & Out) transactions is because by default Transfer transaction have the "From" field pre-populated.
        currentFormClone.splice(formArrayLength, 1);
    }

    return currentFormClone;
};

/**
 * Util to pad Strings with number endings to allowing for correct numerical sorting (e.g. Padding -> '1' to '01')
 * Resolves: Sorting cases such as (1, 10, 11, 2, 20, 21) now becomes (01, 02, 10, 11, 20, 21) [We assume default parameters]
 * @param currentString
 * @param max_chars
 * @param pad_char
 * @returns
 */
const padEndingNumbers = (
    currentString: string,
    max_chars = 2,
    pad_char = "0"
): string => {
    let numericPart: any = currentString.match(/\d+/);
    numericPart = (new Array(max_chars + 1).join(pad_char) + numericPart).slice(
        -max_chars
    );
    const letterPart = currentString.match(/[A-z]+/);
    return letterPart + numericPart;
};

export const sortCashupIDS = (
    cashupA_ID: string,
    cashupB_ID: string,
    locationDetailByCashupId: {
        [key: string]: {
            name: string;
            timePeriod: timePeriodValues;
        };
    }
) => {
    const { name: cashupAName, timePeriod: cashupATimePeriod } =
        locationDetailByCashupId[cashupA_ID];

    const { name: cashupBName } = locationDetailByCashupId[cashupB_ID];

    const compareValue = padEndingNumbers(cashupAName).localeCompare(
        padEndingNumbers(cashupBName)
    );

    if (compareValue === 0) {
        // In scenarios where the two items being compared are the same Location we sorted via time period (AM before PM)
        if (cashupATimePeriod === 1) {
            // 1 == AM time period
            return -1;
        }
        return 1;
    }
    return compareValue;
};

export const sortTableDataByShiftType = (
    cashupA: InitDataResponseType,
    cashupB: InitDataResponseType,
    locationDetailByCashupId: {
        [key: string]: {
            name: string;
            timePeriod: timePeriodValues;
            shiftType: number;
        };
    }
) => {
    const { shiftType: cashupAShiftType } =
        locationDetailByCashupId[cashupA.cashup_id];

    const { shiftType: cashupBShiftType } =
        locationDetailByCashupId[cashupB.cashup_id];

    return cashupAShiftType > cashupBShiftType ? 1 : -1;
};

/** This method is to help guarantees that the TableData is sorted identically to the output the above "sortCashupIDS" method.
 * Note: In most cases the sort shouldn't change anything this method is to prevent any edge cases where events/data is received out of expected order, which would break the row based indexing of the table.
 */
export const sortTableData = (
    cashupA: InitDataResponseType,
    cashupB: InitDataResponseType,
    locationDetailByCashupId: {
        [key: string]: {
            name: string;
            timePeriod: timePeriodValues;
        };
    }
) => {
    const { name: cashupAName, timePeriod: cashupATimePeriod } =
        locationDetailByCashupId[cashupA.cashup_id];

    const { name: cashupBName } = locationDetailByCashupId[cashupB.cashup_id];

    const compareValue = padEndingNumbers(cashupAName).localeCompare(
        padEndingNumbers(cashupBName)
    );

    if (compareValue === 0) {
        // In scenarios where the two items being compared are the same Location we sorted via time period (AM before PM)
        if (cashupATimePeriod === 1) {
            // 1 == AM time period
            return -1;
        }
        return 1;
    }
    return compareValue;
};

export const parseTimePeriod = (timePeriod: timePeriodValues) => {
    if (timePeriod === 1) return "AM";
    if (timePeriod === 2) return "PM";
    return undefined;
};

export const countTransactions = (transactions: any[][]) => {
    let sum = 0;
    transactions.forEach((currentTransaction) => {
        currentTransaction.forEach(() => {
            sum += 1;
        });
    });
    if (sum === 0) return ``;

    return `[${sum}]`;
};
export const extractSubLocation = (
    locations: ExtendedLocationItemWithChildren[],
    previousTitle?: string
): { title: string; value: string; children: any[]; venue?: string }[] => {
    return locations.map((location) => {
        // console.log({checkLocation: location})
        const currentTitle = previousTitle
            ? `${previousTitle} - ${location.name}`
            : undefined;

        return {
            title: currentTitle ?? location.name,
            value: location.location_id,
            children: location.sub_locations
                ? currentTitle
                    ? extractSubLocation(location.sub_locations, currentTitle)
                    : extractSubLocation(location.sub_locations)
                : [],
            disabled: checkForSublocations(location),
            venue: location.venue,
            location_type: location.location_type,
        };
    });
};

export const extractSubLocationWithoutDisable = (
    locations: ExtendedLocationItemWithChildren[]
): { title: string; value: string; children: any[] }[] => {
    return locations.map((location) => {
        return {
            title: location.name,
            value: location.location_id,
            children: location.sub_locations
                ? extractSubLocationWithoutDisable(location.sub_locations)
                : [],
        };
    });
};

export const isNumber = (value: number | string | undefined): boolean => {
    return typeof value === "number" && isFinite(value);
};
export const stringSort = (a: string, b: string): number => {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
};

/**
 * Note: startDate & endDate are assumed to be a valid Date string IF passed as a string
 *
 * @param startDate
 * @param endDate
 * @param timeScale
 * @returns
 */

export const fetchUserToken = async (): Promise<string | undefined> => {
    const data = await getAuthTokens();
    return data?.access_token;
};

export const displayTwoTimePeriods = (
    start: string | Date,
    end: string | Date
): string => {
    if (dayjs(start).date() == dayjs(end).date()) {
        return `${dayjs(start).format("DD/MM/YYYY HH:mm")} - ${dayjs(end).format(
            "HH:mm"
        )} `;
    } else {
        return `${dayjs(start).format("DD/MM/YYYY HH:mm")} - ${dayjs(end).format(
            "DD/MM/YYYY HH:mm"
        )} `;
    }
};

// TODO Slowly decomission this and replace "withTouched" version
export const convertFormDataToKeyValue = (fields: FieldData[]) =>
    fields.map((field) => {
        const obj: KeyValuePair = {};
        if (Array.isArray(field.name)) {
            obj[`${field.name[0]}`] = field.value;
        } else {
            obj[`${field.name}`] = field.value;
        }
        return obj;
    });

// Note: This method assumed that all keys are unique. This does not properly handle key conflicts (the most recent will be overwritten)
export const convertFormDataToKeyValueWithTouched = (fields: FieldData[]) => {
    const keyValue: TransformedKeyValueFields = {};
    fields.forEach((field) => {
        if (Array.isArray(field.name)) {
            keyValue[`${field.name[0]}`] = {
                value: field.value,
                touched: field.touched ?? false,
            };
        } else {
            keyValue[`${field.name}`] = {
                value: field.value,
                touched: field.touched ?? false,
            };
        }
    });
    return keyValue;
};

export const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
};

export function isFloat(val: string) {
    const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(val)) return false;

    const result = parseFloat(val);
    if (isNaN(result)) return false;
    return true;
}

export const handleNaN = (value: number) => {
    if (isNaN(value)) return 0;
    return value;
};

export function precisionRound(number: number, precision = 2) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
}

export function isInt(val: string) {
    const intRegex = /^-?\d+$/;
    if (!intRegex.test(val)) return false;

    const intVal = parseInt(val, 10);
    return parseFloat(val) == intVal && !isNaN(intVal);
}

export const applyTooltipToNewTransactionFormField = (
    fieldTitle:
        | "Tender Split"
        | "Amount"
        | "Class Splits"
        | "Description"
        | "Track Balances"
        | "In POS as sale"
        | "Who (Track Balance Modal)"
        | "Balance (Track Balance Modal)"
        | "In POS as Sale (POS Modal)"
        | "Class (POS Modal)"
        | "Tender (POS Modal)"
        | "Out Type"
        | "Out Type (Out Modal)"
        | "In POS as Sale (Out Modal)"
        | "Class (Use Deposit) (Out Modal)"
        | "Tender (Use Deposit) (Out Modal)"
        | "Tender (Refund) (Out Modal)"
        | "Class (Refund) (Out Modal)"
        | "From"
        | "To"
        | "Expected"
        | "Actual"
        | "Variance"
        | "Account"
        | "Who"
        | "Reason"
) => ToolTips.TransactionFormFields[fieldTitle];

export const extractGSTValue = (amount: number) => {
    const PRE_CUT = amount - amount / (GST_DECIMAL + 1);
    return Math.round(PRE_CUT * 100) / 100;
};

export const roundToTwoDecimal = (num: number) => {
    return Math.round(num * 100) / 100;
};

export const isEftposCountTransactionValid = (
    eftposCount: EftposCountTransactionInit
) => {
    const variance = eftposCount.expected - eftposCount.actual;
    const amountAllocatedTenderSplit = eftposCount.account_split.reduce(
        (previous, currentTenderSplit) => previous + currentTenderSplit.amount,
        0
    );

    if (amountAllocatedTenderSplit !== eftposCount.actual) return false;

    if (eftposCount.action_account_id === "OPEN MODAL") {
        const amountAllocatedToClassSplit = eftposCount.class_split?.reduce(
            (previous, currentClassSplit) => previous + currentClassSplit.amount,
            0
        );

        if (amountAllocatedToClassSplit !== variance) return false;
    }
    if (eftposCount.action_account_id === undefined) return false;

    return true;
};

export const calculateEftposImbalanceFlag = (
    rowIndex: number,
    tableData: TableDataType[],
    eftpos_value: number
): boolean => {
    if (
        !tableData[rowIndex] ||
        tableData[rowIndex]?.eftpos_count_transactions?.length > 0 ||
        tableData[rowIndex].eftpos_count_transactions.length === 1
    ) {
        // Note: There will ever only be 1 Eftpos Count transaction
        return !isEftposCountTransactionValid(
            tableData[rowIndex].eftpos_count_transactions[0]
        );
    }

    // There is no Eftpos Transaction required (Skipping future checks)
    if (eftpos_value === 0) return false;

    return true;
};

export const sanitiseString = (amount: string): string => {
    let result = amount.replace(/[^0-9.-]/g, "");
    const firstPos = result.indexOf(".");
    const secondPos = result.slice(firstPos + 1).indexOf(".");
    if (firstPos !== -1 && secondPos !== -1) {
        result = result.slice(0, firstPos + secondPos + 1);
    }
    return result;
};

export const parseFullName = (fullName: string): string[] => {
    const processedFullName = fullName.trim();
    if (!processedFullName.length) return [];
    let [firstName, lastName] = processedFullName.split(" ");
    firstName = firstName
        ? firstName.charAt(0).toUpperCase() + firstName.slice(1)
        : " ";
    lastName = lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : " ";
    return [firstName, lastName];
};

export const parseName = (
    firstName: string,
    lastName: string
): string | undefined => {
    if (!firstName.trim() || !lastName.trim()) {
        console.log("Error,name empty");
        return;
    } else
        return `${firstName.trim().charAt(0).toUpperCase()}${firstName.slice(
            1
        )} ${lastName.trim().charAt(0).toUpperCase()}${lastName.slice(1)}`;
};

export const contactSupport = () => {
    const iframe = document.getElementById("jsd-widget") as HTMLIFrameElement | null;
    const iframeContent = iframe?.contentDocument;
    const button =
        iframeContent &&
        (iframeContent!.querySelector("#help-button") as HTMLElement);
    button?.click();
};
