import React, { useCallback, useMemo } from "react";
import { usePushCashupMutation } from "Redux/StateSlices/Pusher";
import { InitDataResponseType } from "../../Pages/CashupHome/CashupTabs/POS/@types";
import produce from "immer";
import { CashupSubLocationItem } from "Redux/StateSlices/GroupData/VenuesAPI";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import {
    countTransactions,
    parseTimePeriod,
    sortCashupIDS,
    sortTableData,
    transformLocationsToLocationDetailByCashup,
} from "../../utils/utilities";
import { TableRows } from "V2/Table/TableBody";
import { Table } from "V2/Table/Table";
import set from "lodash/set";
import { TableConfigs } from "V2/Table/TableHeader";
import cloneDeep from "lodash/cloneDeep";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";
import { onTransferTransactionSubmission } from "../Gaming/utils";
import { TableDataType } from "../POS/POSPanel";
import { AntDFormState, AntDFormStateWithoutSplit } from "@types";
import { formatDollarField, prependDollarSign } from "@Constants";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import { DEFAULT_DEBOUNCE_TIME } from "@Constants/debounce";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import {
    onPaymentTransactionRemove,
    onPaymentTransactionSubmission,
} from "../../Pages/CashupHome/CashupTabs/POS/utils";
import { TransactionsFormTransfersOffice } from "Pages/CashupHome/ExcelTable/TransactionsFormTransfersOffice";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

interface Props extends PanelHOCComponentProps {
    name: string;
    currentLocations: ExtendedLocationItemWithChildren;
    cashupIds: string[];
    subLocations: CashupSubLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    allLocations: ExtendedLocationItemWithChildren[];
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
    submitted: boolean;
}

export const OfficePanel: React.FC<Props> = ({
    currentLocations,
    cashupIds,
    subLocations,
    hierarchicalLocations,
    accountsData,
    classesData,
    onClickHandler,
    isInFocus,
    submitted,
    allLocations,
}) => {
    const [updateCashup] = usePushCashupMutation();
    const [tableData, setTableData] = React.useState<InitDataResponseType[]>([]);

    const locationDetailByCashupId = useMemo(
        () => transformLocationsToLocationDetailByCashup(subLocations),
        [subLocations]
    );

    const updateTableData = (cashup: InitDataResponseType) =>
        setTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);
                    draft.sort((cashupA, cashupB) =>
                        sortTableData(cashupA, cashupB, locationDetailByCashupId)
                    );
                }
            });

            return updatedTableData;
        });

    const sortedCashupIDS = useMemo(
        () =>
            cloneDeep(cashupIds).sort((cashupA_ID, cashupB_ID) =>
                sortCashupIDS(cashupA_ID, cashupB_ID, locationDetailByCashupId)
            ),
        [cashupIds, locationDetailByCashupId]
    );

    const { isLoading } = useCashupRealtime({
        cashupIds: sortedCashupIDS,
        onCashupInitialized: updateTableData,
        onCashupUpdated: updateTableData,
    });

    const handleCashupChanged = useCallback(
        (updatedCashup: InitDataResponseType) =>
            updateCashup(updatedCashup).unwrap(),
        [updateCashup]
    );
    /**
     * Method invoked on tableData change to sync with backend
     * @param rowIndex
     * @param currentTableState
     */
    const onRowDataChange = React.useCallback(
        (rowIndex: number, tableData: TableDataType[]) => {
            handleCashupChanged(tableData[rowIndex]);
        },
        [handleCashupChanged]
    );

    const onPaymentSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormState[], rowIndex: number) =>
            onPaymentTransactionSubmission(
                data,
                rowIndex,
                accountsData,
                tableData,
                onRowDataChange
            ),
        300
    );

    const onTransferTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithoutSplit[], rowIndex: number) =>
            onTransferTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    if (isLoading || cashupIds.length !== tableData.length) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "-35px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"small"}
            />
        );
    }

    const dataSources: TableRows[] = tableData.map((cashup, rowIndex) => {
        const {
            cashup_id,
            cash_count,
            transfer_transactions,
            status,
            transfer_transactions_to,
            payment_transactions,
        } = cashup;

        const currentLocationID = locationDetailByCashupId[cashup_id].location_id;

        // Update and sort locations in a single step
        const allHierarchicalLocations = hierarchicalLocations
            .map((location) => {
                const updatedSublocations = location.sub_locations.map(
                    (sublocation) => {
                        // Check for location_type 7 and append cash_count.expected to the name

                        if (sublocation.location_id === currentLocationID) {
                            console.log(
                                `${sublocation.name}${
                                    cash_count.expected_cash
                                        ? ` ${cash_count.expected}`
                                        : ``
                                }`
                            );

                            return {
                                ...sublocation,
                                name: `${sublocation.name}${
                                    cash_count.expected_cash
                                        ? ` (${prependDollarSign(
                                              cash_count.expected_cash
                                          )})`
                                        : ``
                                }`,
                            };
                        }
                        return sublocation;
                    }
                );

                return {
                    ...location,
                    sub_locations: updatedSublocations,
                };

                return location;
            })
            // Sort by the location name
            .sort((locationA, locationB) =>
                locationA.name.localeCompare(locationB.name)
            );

        const sortedLocations = currentLocations.sub_locations.some(
            (sub) => sub.location_type === LocationType.keno_trade_till
        )
            ? allHierarchicalLocations
                  .map((location) => ({ ...location }))
                  .filter((location) => location.location_type !== LocationType.safe)
            : [...allHierarchicalLocations];

        return [
            {
                value: locationDetailByCashupId[cashup_id].name,
                readOnly: true,
                subValue: parseTimePeriod(
                    locationDetailByCashupId[cashup_id].timePeriod
                ),
            },
            {
                value: cash_count.opening_balance ?? 0,
                readOnly: true,
            },
            {
                value: tableData[rowIndex]
                    ? countTransactions([
                          transfer_transactions ?? [],
                          transfer_transactions_to ?? [],
                          payment_transactions ?? [],
                      ])
                    : "",
                editModal: (closeModal) => (
                    <TransactionsFormTransfersOffice
                        key={cashupIds[0]}
                        toLocations={sortedLocations}
                        fromLocations={sortedLocations}
                        showTransfersFrom={allHierarchicalLocations}
                        showTransfersTo={allHierarchicalLocations}
                        onTransferTransactionSubmission={(data) =>
                            onTransferTransactionSubmissionDebounce(data, rowIndex)
                        }
                        TransferTransactions={transfer_transactions ?? []}
                        currentExpectedValue={cash_count.expected_cash}
                        TransferTransactionsReadOnly={transfer_transactions_to ?? []}
                        currentLocationID={currentLocationID}
                        allLocations={allLocations}
                        accountsData={accountsData}
                        classesData={classesData}
                        PaymentTransactionData={payment_transactions ?? []}
                        onPaymentTransactionSubmission={(data) =>
                            onPaymentSubmissionDebounce(data, rowIndex)
                        }
                        onPaymentTransactionRemove={(data) =>
                            onPaymentTransactionRemove(
                                data,
                                rowIndex,
                                accountsData,
                                tableData,
                                onRowDataChange
                            )
                        }
                        disabled={submitted}
                        forceIncludeBank
                        onModalClose={closeModal}
                        updateShiftType={true}
                    />
                ),
                modalTitle: "Transactions",
                hideEditModalFooter: true,
            },
            {
                value: formatDollarField(cash_count.expected_cash ?? 0),
                readOnly: true,
                statusField: () =>
                    cash_count.expected_cash === null ? status : null,
            },
        ];
    });

    const tableConfigs: TableConfigs = [
        { columnTitle: "Location" },
        { columnTitle: "Opening balance" },
        { columnTitle: "Other Transactions" },
        { columnTitle: "Expected" },
    ];

    return (
        <>
            <Table
                onCellValueChanged={({ row, updatedValue, propertyPath }) => {
                    const updatedCashup = produce(tableData[row], (draft) => {
                        set(draft, propertyPath, updatedValue);
                    });
                    handleCashupChanged(updatedCashup);
                }}
                dataSources={dataSources}
                tableConfigs={tableConfigs}
                onClickHandler={onClickHandler}
                isInFocus={isInFocus}
                evenDistribution
            />
        </>
    );
};
