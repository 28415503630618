export const areaTypes = [
    { id: "1", label: "POS", value: "pos" },
    { id: "2", label: "Gaming", value: "gaming" },
    { id: "4", label: "Wagering", value: "wagering" },
    { id: "5", label: "Transactions", value: "transactions" },
    { id: "3", label: "Admin", value: "admin" },
];

export const reportTypes = [
    {
        areaTypeId: "1",
        options: [
            { id: "1", label: "Classes", value: "classes" },
            { id: "2", label: "Tenders", value: "tenders" },
            { id: "3", label: "Variances", value: "variances" },
            { id: "4", label: "Classes Summary", value: "classes-summary" },
            { id: "5", label: "Tenders Summary", value: "tenders-summary" },
            { id: "6", label: "Variances Summary", value: "variances-summary" },
        ],
    },
    {
        areaTypeId: "2",
        options: [
            { id: "1", label: "Metered Net Profit", value: "meteredNetProfit" },
            { id: "2", label: "Actual Net Profit", value: "actualNetProfit" },
            { id: "3", label: "Variance", value: "gamingVariance" },
            { id: "4", label: "Payouts", value: "payouts" },
            { id: "5", label: "CRT", value: "crt" },
        ],
    },
    {
        areaTypeId: "3",
        options: [
            { id: "1", label: "Approvals", value: "approvalsReport" },
            { id: "2", label: "Bankable", value: "bankable" },
            { id: "3", label: "Shift Report", value: "shiftReport" },
            { id: "4", label: "AUSTRAC Compliance", value: "austracCompliance" },
        ],
    },
    {
        areaTypeId: "4",
        options: [{ id: "1", label: "Payouts", value: "payouts" }],
    },
    {
        areaTypeId: "5",
        options: [{ id: "1", label: "Petty Cash", value: "pettyCash" }],
    },
];

export const apiUrlTypes: { [key: string]: string } = {
    "venue-pos-classes": "report/class/breakdown",
    "venue-pos-tenders": "report/account/breakdown",
    "venue-pos-variances": "report/pos/variance",
    "venue-gaming-meteredNetProfit": "report/gaming/profit",
    "venue-gaming-actualNetProfit": "report/gaming/actual-net-profit",
    "venue-gaming-gamingVariance": "report/gaming/actual-v-metered",
    "venue-gaming-payouts": "report/gaming/payouts",
    "venue-wagering-payouts": "report/gaming/payouts",
    "venue-gaming-crt": "report/gaming/crt",
    "venue-pos-classes-summary": "report/classes/summary",
    "venue-pos-tenders-summary": "report/acounts/summary",
    "venue-pos-variances-summary": "report/variance/summary",
    "venue-admin-shiftReport": "report/shift",
    "venue-admin-shiftReport-prompts": "report/shift/prompts",
    "venue-transactions-pettyCash": "report/transactions/petty-cash",
    "venue-admin-approvalsReport": "report/submit-and-approve/approvals-report",
    "venue-admin-bankable": "report/all-area/bankable-transactions",
    "venue-admin-bankableOverview": "report/all-area/bankable-overview",
};

export const customPalette = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
    "#aec7e8",
    "#ffbb78",
    "#98df8a",
    "#ff9896",
    "#c5b0d5",
    "#c49c94",
    "#f7b6d2",
    "#c7c7c7",
    "#dbdb8d",
    "#9edae5",
    "#393b79",
    "#637939",
    "#8c6d31",
    "#843c39",
    "#7b4173",
    "#5254a3",
    "#637939",
    "#8c6d31",
    "#843c39",
    "#7b4173",
    "#393b79",
    "#637939",
    "#8c6d31",
    "#843c39",
    "#7b4173",
    "#b5cf6b",
    "#d6616b",
    "#ce6dbd",
    "#3182bd",
    "#e6550d",
];

export const keyNumbers = ["Total Sales", "Variance", "Petty Cash", "Promotions"];

export const SUPPORT_URL = "https://help.quantaco.co/knowledge";
