import React, { useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";
import { PusherProvider } from "../hooks/usePusher";
import { useUser } from "../Context/UserContextConstants";
import { getLoggedInUser } from "../ApiV2/Helpers/getLoggedInUser";
import { useQuery } from "react-query";

export function AuthProtectedRoute({ component: Component, ...rest }: RouteProps) {
    const { user, setUser } = useUser();
    const token = localStorage.getItem("token");
    const { data: activeUser, refetch: fetchActiveUser } = useQuery(
        ["activeUser", token],
        () => getLoggedInUser(),
        { enabled: false }
    );

    useEffect(() => {
        if (!user) fetchActiveUser();
    }, [user]);

    useEffect(() => {
        if (activeUser) setUser(activeUser);
    }, [activeUser]);

    return (
        <PusherProvider>
            <Route {...rest} component={Component} />
        </PusherProvider>
    );
}
