import React, { useState, useEffect } from "react";
import { Row, Col, Space, Typography, Spin, Tooltip } from "antd";
import { FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import AustracPrompts from "./AustracPrompts";
import { getAustracVenueReport } from "ApiV2/Helpers/getAustracVenueReport";
import { AustracReportItem } from "@interfaces/index";
import {
    DownloadLoadingContainer,
    LocationContainer,
    GroupLoadingContainer,
    TableTitle,
} from "../CashupReportStyled";
import {
    checkDateRange,
    downloadCsvReport,
    downloadPdfReport,
    formatDate,
    prepareAustracVenueCsvData,
} from "../../utils";
import { SUPPORT_URL } from "../../const";

const { Title } = Typography;

interface AustracReportProps {
    reportType: string;
    date: { start_date: string; end_date: string };
    venue: string;
    venueName: string;
    calendarStatus: boolean;
}

const CashupAustracReport: React.FC<AustracReportProps> = ({
    reportType,
    date,
    venue,
    venueName,
    calendarStatus,
}) => {
    const [rangeCheck, setRangeCheck] = useState(false);
    const [download, setDownload] = useState(false);

    useEffect(() => {
        if (checkDateRange(date, 60)) {
            setRangeCheck(true);
        }
    }, []);

    //get austrac report data
    const fetchReportData = async () => {
        if (!calendarStatus && !checkDateRange(date, 60)) {
            const response = await getAustracVenueReport(
                venue,
                date.start_date,
                date.end_date
            );
            const result = await response.json();
            return result.data;
        }
    };

    const {
        data: reportData = [],
        isFetching: reportDataLoading,
        error: reportError,
        refetch: refetchAustracData,
    } = useQuery(["venueAustracReportData"], fetchReportData, {
        enabled:
            date.start_date.length > 0 &&
            date.end_date.length > 0 &&
            !!venue &&
            !checkDateRange(date, 60),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        refetchAustracData();
    }, [refetchAustracData]);

    //download pdf report
    const onClick = async () => {
        setDownload(true);
        await downloadPdfReport(reportType, "austrac-report", venueName, date);
        setDownload(false);
    };

    //download csv report
    const onCSVClick = async () => {
        setDownload(true);
        const csvData = prepareAustracVenueCsvData(reportData);
        downloadCsvReport(reportType, venueName, date, csvData);
        setDownload(false);
    };

    if (reportDataLoading) {
        return (
            <GroupLoadingContainer>
                <CentredSpinner size="large" />
            </GroupLoadingContainer>
        );
    }

    if (rangeCheck) {
        return (
            <Title level={5}>
                Cashup austrac compliance reports are limited to a 60-day date range.
                Please adjust your date range selection.
            </Title>
        );
    }

    if (reportError) {
        return (
            <Title level={5}>
                There was an error when trying to retrieve report data. Please
                contact{" "}
                <a href={SUPPORT_URL} target="_blank" rel="noopener noreferrer">
                    Quantaco Support
                </a>{" "}
                for assistance.
            </Title>
        );
    }

    if (
        (date.start_date.length < 1 && date.end_date.length < 1) ||
        reportData.length === 0
    ) {
        return (
            <Title level={5}>
                Cashup data is not available for the selected combination of filters.
                Please review and update your selection.
            </Title>
        );
    }

    return (
        <>
            <div id="austrac-report" style={{ backgroundColor: "#f5f5f5" }}>
                <Space
                    size="middle"
                    direction="vertical"
                    style={{
                        width: "100%",
                    }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="space-between"
                        align="middle"
                        className="hide-for-pdf"
                    >
                        <Col>
                            <Title level={4}>{`${reportType}`}</Title>
                        </Col>
                        <Col>
                            {!reportDataLoading ? (
                                <div style={{ display: "flex", gap: "15px" }}>
                                    <Tooltip title="Download PDF">
                                        <FilePdfOutlined
                                            style={{
                                                fontSize: "28px",
                                                color: "#113469",
                                            }}
                                            onClick={onClick}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Download CSV">
                                        <FileTextOutlined
                                            style={{
                                                fontSize: "28px",
                                                color: "#113469",
                                            }}
                                            onClick={onCSVClick}
                                        />
                                    </Tooltip>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    {reportData.map((item: AustracReportItem) => (
                        <div key={`${item.shift_date}`}>
                            {item.responses.length > 0 ? (
                                <Row gutter={[20, 2]} key={`${item.shift_date}`}>
                                    <Col xs={24}>
                                        <LocationContainer>
                                            <TableTitle
                                                style={{
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                {formatDate(item.shift_date)}
                                            </TableTitle>
                                            <AustracPrompts austracItemData={item} />
                                        </LocationContainer>
                                    </Col>
                                </Row>
                            ) : null}
                        </div>
                    ))}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating Report... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};

export default CashupAustracReport;
