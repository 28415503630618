import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Space, Spin, Statistic } from "antd";
import { useQuery } from "react-query";
import CountUp from "react-countup";
import { DownloadOutlined } from "@ant-design/icons";
import { useIsMobile } from "../../Pages/CashupHome/CollaborationTable/useIsMobile";
import { Formatter, valueType } from "antd/es/statistic/utils";
import { CashupReportType } from "@Constants/enum/Cashup";
import ShiftReportClasses from "./ShiftReportClasses";
import ShiftReportPrompts from "./ShiftReportPrompts";
import { apiUrlTypes, keyNumbers } from "Pages/CashupReportNew/const";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { getCashupReport } from "ApiV2/Helpers/getCashupReport";
import { getShiftPrompts } from "ApiV2/Helpers/getShiftPrompts";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import ShiftReportRevenueMetrics from "Pages/CashupReportNew/Components/ShiftReport/ShiftReportRevenueMetrics";
import ShiftReportWageMetrics from "Pages/CashupReportNew/Components/ShiftReport/ShiftReportWageMetrics";
import {
    checkZeroValues,
    checkWazeZeroValues,
    downloadPdfReport,
} from "Pages/CashupReportNew/utils";
import {
    DownloadLoadingContainer,
    KeyNumberContainer,
    LoadingContainer,
    NoShiftDataContainer,
    ShiftMetricsNoDataContainer,
    TableTitle,
} from "Pages/CashupReportNew/Components/CashupReportStyled";
import { SUPPORT_URL } from "Pages/CashupReportNew/const";

const ShiftReportTab = () => {
    const [download, setDownload] = useState(false as boolean);
    const { venueId, formattedDate, venueName, shiftDate } = useVenueSelection();
    const isMobile = useIsMobile();

    const getReportData = async (url: string, signal?: AbortSignal) => {
        const reportUrlType = url;
        //get report data
        const response = await getCashupReport(
            reportUrlType,
            venueId,
            formattedDate,
            formattedDate,
            undefined,
            signal
        );
        return response.json();
    };

    const useFetchReportData = (reportType: string) => {
        const urlType = apiUrlTypes[reportType];
        return useQuery(
            [reportType, venueId, formattedDate],
            ({ signal }) => getReportData(urlType, signal),
            {
                enabled: formattedDate.length > 0 && venueId.length > 0,
                refetchOnWindowFocus: false,
            }
        );
    };

    // Shift Metrics API
    const {
        data: shiftReportData = {},
        isFetching: shiftApiStatus,
        refetch: refetchShiftReportMetrics,
        error: shiftReportError,
    } = useFetchReportData(CashupReportType.AdminShiftReport);

    // POS Classes API
    const {
        data: classesReportData = [],
        isFetching: classesApiStatus,
        refetch: refetchClassesReport,
        error: classesReportError,
    } = useFetchReportData(CashupReportType.PosClasses);

    // Shift prompts API
    const getShiftPromptsData = async (signal?: AbortSignal) => {
        const response = await getShiftPrompts(venueId, formattedDate, signal);
        return response.json();
    };

    const useFetchShiftPromptsData = () => {
        return useQuery(
            ["shiftPrompts", venueId, formattedDate],
            ({ signal }) => getShiftPromptsData(signal),
            {
                enabled: formattedDate.length > 0 && venueId.length > 0,
                refetchOnWindowFocus: false,
            }
        );
    };

    // Shift prompts API
    const {
        data: shiftPromptsData = [],
        isFetching: shiftPromptsApiStatus,
        refetch: refetchShiftPrompts,
        error: shiftPromptsError,
    } = useFetchShiftPromptsData();

    useEffect(() => {
        refetchShiftReportMetrics();
        refetchClassesReport();
        refetchShiftPrompts();
    }, [
        venueId,
        formattedDate,
        refetchShiftReportMetrics,
        refetchClassesReport,
        refetchShiftPrompts,
    ]);

    //check 0 values in shift sales revenue data
    const salesNullCheck: boolean = useMemo(() => {
        if (
            shiftReportData &&
            shiftReportData.sales &&
            Array.isArray(shiftReportData.sales.data)
        ) {
            return shiftReportData.sales.data.length > 0
                ? checkZeroValues(shiftReportData.sales.data)
                : false;
        }
        return false;
    }, [shiftReportData]);

    //check 0 values in shift gaming data
    const gamingNullCheck: boolean = useMemo(() => {
        if (
            shiftReportData &&
            shiftReportData.sales &&
            Array.isArray(shiftReportData.gaming_data.data)
        ) {
            return shiftReportData.gaming_data.data.length > 0
                ? checkZeroValues(shiftReportData.gaming_data.data)
                : false;
        }
        return false;
    }, [shiftReportData]);

    const formatter: Formatter = (value: valueType) => {
        if (typeof value === "string") {
            return value;
        }
        return <CountUp end={value} separator="," decimals={2} duration={1} />;
    };

    //check 0 values in shift wage data
    const wageNullCheck: boolean = useMemo(() => {
        if (shiftReportData?.wages && Array.isArray(shiftReportData.wages.data)) {
            return shiftReportData.wages.data.length > 0
                ? checkWazeZeroValues(shiftReportData.wages.data)
                : false;
        }
        return false;
    }, [shiftReportData]);

    //download pdf report
    const downloadReport = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const reportName = `Shift`;
        await downloadPdfReport(reportName, "shift-report", venueName, {
            start_date: shiftDate,
            end_date: shiftDate,
        });
        setDownload(false);
    };

    return (
        <>
            <div id="shift-report" style={{ backgroundColor: "#f5f5f5" }}>
                <Space
                    direction="vertical"
                    style={{ width: isMobile ? "90%" : "100%" }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="end"
                        align="middle"
                        className="hide-for-pdf"
                        style={{ marginBottom: "10px" }}
                    >
                        <Col>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                onClick={downloadReport}
                            >
                                Download Report
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        {keyNumbers &&
                            keyNumbers.map((item: string) => (
                                <Col md={12} lg={6} xs={24} key={item}>
                                    <KeyNumberContainer>
                                        {shiftApiStatus ? (
                                            <CentredSpinner size="large" />
                                        ) : shiftReportError ? (
                                            <>
                                                <TableTitle>
                                                    There was an error when trying to
                                                    retrieve shift report data.
                                                    Please contact{" "}
                                                    <a
                                                        href={SUPPORT_URL}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Quantaco Support
                                                    </a>{" "}
                                                    for assistance.
                                                </TableTitle>
                                            </>
                                        ) : (
                                            <>
                                                <Statistic
                                                    valueStyle={{
                                                        fontSize: 28,
                                                        fontWeight: "700 !important",
                                                        color: "#113469",
                                                    }}
                                                    value={
                                                        shiftReportData &&
                                                        shiftReportData.key_numbers
                                                            ? shiftReportData
                                                                  .key_numbers[item]
                                                            : 0
                                                    }
                                                    precision={2}
                                                    prefix={"$"}
                                                    formatter={formatter}
                                                />
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        fontWeight: "SemiBold",
                                                    }}
                                                >
                                                    {item === "Variance"
                                                        ? "Cash Variance"
                                                        : item}
                                                </div>
                                            </>
                                        )}
                                    </KeyNumberContainer>
                                </Col>
                            ))}
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            {shiftApiStatus ? (
                                <KeyNumberContainer>
                                    <CentredSpinner size="large" />
                                </KeyNumberContainer>
                            ) : shiftReportError ? null : (
                                <>
                                    {!salesNullCheck &&
                                    shiftReportData &&
                                    shiftReportData.sales &&
                                    shiftReportData.sales.data.length > 0 ? (
                                        <ShiftReportRevenueMetrics
                                            revenueData={shiftReportData.sales}
                                            chartLabal={"All Locations"}
                                        />
                                    ) : (
                                        <ShiftMetricsNoDataContainer>
                                            <TableTitle>
                                                Revenue data is not available for the
                                                selected date.
                                            </TableTitle>
                                        </ShiftMetricsNoDataContainer>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            {shiftApiStatus ? (
                                <KeyNumberContainer>
                                    <CentredSpinner size="large" />
                                </KeyNumberContainer>
                            ) : shiftReportError ? null : (
                                <>
                                    {!gamingNullCheck &&
                                    shiftReportData &&
                                    shiftReportData.gaming_data &&
                                    shiftReportData.gaming_data.data.length > 0 ? (
                                        <ShiftReportRevenueMetrics
                                            revenueData={shiftReportData.gaming_data}
                                            chartLabal={"Gaming"}
                                        />
                                    ) : (
                                        <ShiftMetricsNoDataContainer>
                                            <TableTitle>
                                                Gaming data is not available for the
                                                selected date.
                                            </TableTitle>
                                        </ShiftMetricsNoDataContainer>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            {shiftApiStatus ? (
                                <KeyNumberContainer>
                                    <CentredSpinner size="large" />
                                </KeyNumberContainer>
                            ) : shiftReportError ? null : (
                                <>
                                    {!wageNullCheck &&
                                    shiftReportData?.wages?.data?.length > 0 ? (
                                        <ShiftReportWageMetrics
                                            wageData={shiftReportData.wages}
                                        />
                                    ) : (
                                        <ShiftMetricsNoDataContainer>
                                            <TableTitle>
                                                Wage data is not available for the
                                                selected date.
                                            </TableTitle>
                                        </ShiftMetricsNoDataContainer>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    {classesApiStatus ? (
                        <Row gutter={[20, 2]}>
                            <Col xs={24}>
                                <LoadingContainer>
                                    <CentredSpinner size="large" />
                                </LoadingContainer>
                            </Col>
                        </Row>
                    ) : classesReportError ? (
                        <>
                            <NoShiftDataContainer>
                                <TableTitle>
                                    There was an error when trying to retrieve pos
                                    classes data. Please contact{" "}
                                    <a
                                        href={SUPPORT_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Quantaco Support
                                    </a>{" "}
                                    for assistance.
                                </TableTitle>
                            </NoShiftDataContainer>
                        </>
                    ) : (
                        <>
                            {classesReportData.length > 0 ? (
                                <ShiftReportClasses reportData={classesReportData} />
                            ) : (
                                <NoShiftDataContainer>
                                    <TableTitle>
                                        Classes data is not available for the
                                        selected date.
                                    </TableTitle>
                                </NoShiftDataContainer>
                            )}
                        </>
                    )}
                    {shiftPromptsApiStatus ? (
                        <Row gutter={[20, 2]}>
                            <Col xs={24}>
                                <LoadingContainer>
                                    <CentredSpinner size="large" />
                                </LoadingContainer>
                            </Col>
                        </Row>
                    ) : shiftPromptsError ? (
                        <>
                            <NoShiftDataContainer>
                                <TableTitle>
                                    There was an error when trying to retrieve shift
                                    prompts data. Please contact{" "}
                                    <a
                                        href={SUPPORT_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Quantaco Support
                                    </a>{" "}
                                    for assistance.
                                </TableTitle>
                            </NoShiftDataContainer>
                        </>
                    ) : (
                        <>
                            {shiftPromptsData.data ? (
                                <ShiftReportPrompts
                                    promptsData={
                                        shiftPromptsData.data
                                            .grouped_prompts_by_category
                                    }
                                />
                            ) : (
                                <NoShiftDataContainer>
                                    <TableTitle>
                                        Shiftt prompts data is not available for the
                                        selected date.
                                    </TableTitle>
                                </NoShiftDataContainer>
                            )}
                        </>
                    )}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating Report... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};
export default ShiftReportTab;
