import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { FieldData } from "@interfaces";
import { AntDFormStateWithoutSplit } from "@types";
import {
    Button,
    Col,
    Form,
    Input,
    Radio,
    Row,
    Select,
    Tooltip,
    TreeSelect,
} from "antd";
import { StyledAntDInput } from "Components/MoneyInput/MoneyInputV2";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useState } from "react";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";
import styled from "styled-components";
import { MoneyFormField } from "../Forms/Components/MoneyFormFields";
import { Field } from "./SalesCountModalFormV2";
import { StyledEmptyLabel } from "./PaymentModalFormV2";
import { clearLastFormFieldIfEmpty } from "utils/utilities";
import { SplitModalField } from "Components/SplitModalField/SplitModalField";
import { useForm } from "antd/lib/form/Form";
import { isDepositValid } from "./DepositInModalFormV2";
import { positiveNumberOnlyValidator } from "../utils";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

export const StyledTitleRow = styled(Row)`
    & > div {
        font-size: 12px;
        font-weight: bold;
        color: #626e84;
    }
`;

export const StyledInput = styled(Input)`
    border-radius: 4px;
    background: #f9fafb;

    &:focus {
        border-color: white;
        background: white;
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
        border-radius: 4px;
    }
`;

export const StyledRadioGroups = styled(Radio.Group)`
    width: 100%;

    .ant-radio-button-wrapper {
        box-sizing: border-box;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #1a81c7 !important;
        background: #fffff;
        width: 50%;
        color: #1a81c7;
        font-size: 12px;
        padding: 0px;
        text-align: center;

        &:nth-child(2) {
            border-radius: 0px 4px 4px 0px;
        }
    }

    .ant-radio-button-wrapper-checked {
        background: #dbe7fa !important;
    }
`;

const StyledRow = styled(Row)`
    .ant-form-item {
        margin-bottom: 4px;
    }
`;

const StyledSelect = styled(Select)<{ disabled: boolean }>`
    ${(props) =>
        props.disabled === false &&
        `.ant-select-selector {
        color: #353940 !important;
        background: #f9fafb !important;
        border: 1px solid #dadfe7 !important;
        border-radius: 4px !important;
    }`}

    ${(props) =>
        props.disabled === true &&
        `.ant-select-selector {
            border: 1px solid #dadfe7 !important;
            border-radius: 4px !important;
        }`}
`;

// Return true if the location has sub_locations
export const checkForSublocations = (location: ExtendedLocationItemWithChildren) => {
    if (location.sub_locations) {
        return location.sub_locations.length !== 0;
    } else {
        return false;
    }
};

const CustomFormField = ({
    field: { type, changeGST, ...rest },
    remove,
    openModal,
    onAmountChange,
    disabled,
    addNewRow,
    isLastItem,
}: {
    field: Field;
    remove?: () => void;
    openModal?: () => void;
    onAmountChange?: (data: any) => void;
    disabled?: boolean;
    addNewRow: (saveOnly?: boolean) => () => void;
    isLastItem?: boolean;
}) => {
    switch (type.name) {
        case "Money":
            return (
                <MoneyFormField
                    {...rest}
                    disabled={disabled}
                    onChange={changeGST ? onAmountChange : undefined}
                />
            );
        case "GST":
            return <StyledAntDInput disabled={disabled} />;
        case "Text":
            return <StyledInput {...rest} disabled={disabled} />;
        case "Dropdown":
            return (
                <StyledSelect disabled={disabled ?? false}>
                    {type.options.map((currentOption, index) => (
                        <Select.Option value={currentOption.value} key={index}>
                            {currentOption.label}
                        </Select.Option>
                    ))}
                </StyledSelect>
            );
        case "Modal":
            return (
                <SplitModalField
                    openModal={openModal}
                    disabled={disabled ?? false}
                />
            );

        case "Radio":
            return (
                <StyledRadioGroups
                    options={type.options}
                    optionType="button"
                    {...rest}
                />
            );

        case "Button":
            return (
                <div style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={
                            <Tooltip
                                title="Save this row"
                                placement="top"
                                arrowPointAtCenter
                                autoAdjustOverflow
                            >
                                <SaveOutlined style={{ color: "#FFF" }} />
                            </Tooltip>
                        }
                        onClick={addNewRow(true)}
                        htmlType="submit"
                    />
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={
                            <Tooltip
                                title="Adding new row"
                                placement="top"
                                arrowPointAtCenter
                                autoAdjustOverflow
                            >
                                <PlusOutlined style={{ color: "#FFF" }} />
                            </Tooltip>
                        }
                        onClick={addNewRow()}
                        disabled={!isLastItem || disabled}
                    />
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={
                            <Tooltip
                                title="Delete this row"
                                placement="top"
                                arrowPointAtCenter
                                autoAdjustOverflow
                            >
                                <DeleteOutlined style={{ color: "#FFF" }} />
                            </Tooltip>
                        }
                        onClick={remove}
                    />
                </div>
            );

        case "TreeSelect":
            return (
                <TreeSelect
                    showSearch
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    treeData={type.locations}
                    placeholder="Please select a location"
                    filterTreeNode={(search, item) => {
                        if (item?.title)
                            return (
                                item.title
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(search.toLowerCase()) >= 0
                            );
                        // Handle undefined cases
                        return false;
                    }}
                    disabled={disabled}
                    treeDefaultExpandAll={true}
                    virtual={false}
                />
            );
        case "TreeSelectLocked":
            return (
                <TreeSelect
                    showSearch
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    treeData={type.locations
                        .filter((currentParentLocation) => {
                            let flag = false;
                            currentParentLocation.children!.forEach(
                                (currentChild) => {
                                    if (currentChild.value === type.key) {
                                        flag = true;
                                    }
                                }
                            );
                            if (
                                currentParentLocation.location_type ==
                                    LocationType.bank ||
                                currentParentLocation.location_type ==
                                    LocationType.safe
                            ) {
                                flag = true;
                            }
                            return flag;
                        })
                        .map((currentParent) => {
                            return {
                                ...currentParent,
                                children: currentParent.children!.filter(
                                    (currentChild) => {
                                        if (
                                            currentChild.value === type.key ||
                                            currentChild.location_type ==
                                                LocationType.safe
                                        ) {
                                            return true;
                                        }
                                    }
                                ),
                            };
                        })}
                    placeholder="Please select a location"
                    filterTreeNode={(search, item) => {
                        if (item?.title)
                            return (
                                item.title
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(search.toLowerCase()) >= 0
                            );
                        // Handle undefined cases
                        return false;
                    }}
                    disabled={disabled}
                    treeDefaultExpandAll={true}
                    virtual={false}
                />
            );
        default:
            throw new Error("This should be impossible");
    }
};

const TransferFormComponent = ({
    formIndex,
    fields,
    removeForm,
    formState,
    onFormChange,
    addNewRow,
    pushForms,
    disabled,
    isLastItem,
}: {
    formIndex: number;
    fields: Field[];
    removeForm: () => void;
    formState: { formData: FieldData[] };
    onFormChange: (data: FieldData[]) => void;
    addNewRow: () => void;
    pushForms: (data: FieldData[]) => void;
    disabled?: boolean;
    isLastItem?: boolean;
}) => {
    const [form] = useForm();
    const addNewRowWithValidation = (saveOnly?: boolean) => () => {
        form.validateFields()
            .then(() => {
                form.submit();
                if (!saveOnly) {
                    addNewRow();
                }
            })
            .catch(() => {
                return;
            });
    };

    return (
        <>
            <Form
                layout="vertical"
                name={`TransferForm-${formIndex}`}
                fields={formState.formData}
                onFieldsChange={(_, allFields) => {
                    onFormChange(allFields);
                }}
                onFinish={(data) => {
                    const result: Array<FieldData> = [];
                    for (const [key, value] of Object.entries(data)) {
                        result.push({
                            name: key,
                            value: value ? value : "",
                        });
                    }
                    console.log("push forms in form");
                    pushForms(result);
                }}
                style={{ width: "100%" }}
                form={form}
                disabled={disabled}
            >
                <StyledRow gutter={8}>
                    {fields.map((field) => (
                        <Col span={field.colSpan} key={field.key}>
                            <Form.Item
                                key={field.key}
                                label={
                                    formIndex === 0 ? (
                                        field.isButton ? (
                                            <StyledEmptyLabel />
                                        ) : (
                                            field.name
                                        )
                                    ) : null
                                }
                                name={field.name}
                                rules={positiveNumberOnlyValidator(field, [
                                    {
                                        required: field.required,
                                        message: "Please enter a value",
                                    },
                                ])}
                            >
                                {CustomFormField({
                                    field,
                                    remove: removeForm,
                                    addNewRow: addNewRowWithValidation,
                                    disabled: field.disabled || disabled,
                                    isLastItem: isLastItem,
                                })}
                            </Form.Item>
                        </Col>
                    ))}
                </StyledRow>
            </Form>
        </>
    );
};
const TransferForm = React.memo(TransferFormComponent);

interface Props {
    fields: Field[];
    initialDataForForms: AntDFormStateWithoutSplit[];
    onChange: (data: AntDFormStateWithoutSplit[]) => void;
    currentLocationID?: string;
    disabled?: boolean;
    setFormNeedToSave?: (category: string, isToAdd: boolean) => void;
    locationMapping?: Map<string, number>;
}

export const TransferModalFormV2: React.FC<Props> = ({
    fields,
    onChange,
    initialDataForForms,
    currentLocationID,
    disabled,
    setFormNeedToSave,
    locationMapping,
}) => {
    const [formsData, setFormsData] = useState(initialDataForForms);

    const addNewRow = () => {
        const formDataDeepClone = cloneDeep(formsData);
        currentLocationID
            ? formDataDeepClone.push({
                  formData: [{ name: "From", value: currentLocationID }],
                  formID: Math.random().toString(),
              })
            : formDataDeepClone.push({
                  formData: [{ name: "From", value: undefined }],
                  formID: Math.random().toString(),
              });

        setFormsData(formDataDeepClone);
        onChange(formDataDeepClone);
    };

    /**
     *  Removed a form at a specified index
     * @param formIndex
     */
    const removeForm = (formIndex: number) => {
        const formDataDeepClone = cloneDeep(formsData);
        formDataDeepClone.splice(formIndex, 1);
        const currentFormFieldsData = clearLastFormFieldIfEmpty(
            formDataDeepClone,
            true
        ) as AntDFormStateWithoutSplit[];

        if (currentFormFieldsData.length === 0) {
            setFormsData([
                {
                    formData: [{ name: "From", value: currentLocationID }],
                    formID: Math.random().toString(),
                },
            ]);
        } else {
            setFormsData(currentFormFieldsData);
        }
        onChange(currentFormFieldsData);
        setFormNeedToSave && setFormNeedToSave("Transfers from", false);
    };

    const updateWithLocationType = (formData: FieldData[], locationMapping: any) => {
        return formData.map((field: any) => {
            // Find the "FromLocationType" field and update its value
            if (field.name && field.name[0] === "FromLocationType") {
                // Find the "From" location ID and map to location type
                const fromLocationType = locationMapping.get(
                    formData
                        .find((item: any) => item.name[0] === "From")
                        ?.value?.split(" ")[0] || ""
                );
                if (fromLocationType !== undefined) {
                    field.value = fromLocationType;
                }
            }
            // Find the "ToLocationType" field and update its value
            else if (field.name && field.name[0] === "ToLocationType") {
                // Find the "To" location ID and map to location type
                const toLocationType = locationMapping.get(
                    formData
                        .find((item: any) => item.name[0] === "To")
                        ?.value?.split(" ")[0] || ""
                );
                if (toLocationType !== undefined) {
                    field.value = toLocationType;
                }
            }
        });
    };

    /**
     * Triggered on "onFieldsChange" form events.
     * @param rowIndex
     * @param formData
     */
    const updateFormState = (rowIndex: number, formData: FieldData[]) => {
        const formDataDeepClone = cloneDeep(formsData);
        const CurrentItem = formDataDeepClone[rowIndex];
        updateWithLocationType(formData, locationMapping);
        formDataDeepClone.splice(rowIndex, 1, {
            formData: formData,
            formID: CurrentItem.formID,
        });
        setFormsData(formDataDeepClone);
        if (isDepositValid(formData)) {
            setFormNeedToSave && setFormNeedToSave("Transfers from", true);
        }
    };

    const pushForms = useCallback(
        (formData: FieldData[]) => {
            if (isDepositValid(formData)) {
                const formDataDeepClone = cloneDeep(formsData);
                onChange(formDataDeepClone);
                setFormNeedToSave && setFormNeedToSave("Transfers from", false);
            }
        },
        [onChange, formsData, setFormNeedToSave]
    );

    return (
        <>
            <StyledRow>
                {formsData.map((currentFormData, index) => (
                    <TransferForm
                        key={currentFormData.formID}
                        formIndex={index}
                        removeForm={() => {
                            removeForm(index);
                        }}
                        fields={fields}
                        formState={currentFormData}
                        onFormChange={(data: FieldData[]) =>
                            updateFormState(index, data)
                        }
                        addNewRow={addNewRow}
                        pushForms={pushForms}
                        disabled={disabled}
                        isLastItem={index === formsData.length - 1}
                    />
                ))}
            </StyledRow>
        </>
    );
};
