import React, { ReactElement, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

// Pages/components
import { RoutePaths } from "./RoutesEnum";
import PageNotFound from "../Pages/ErrorPages/PageNotFound";
import { AuthProtectedRoute } from "./AuthProtectedRoute";
import UnAuthorised from "../Pages/ErrorPages/UnAuthorised";

import { QuantacoLoader } from "../Components/QuantacoLoader/QuantacoLoader";
import { AuditReportPage } from "../Pages/CashupReport/AuditReportPage";
import { DefaultLayout } from "../Components/Layout";
import CashupReportOverview from "../Pages/CashupReportOverview/CashupReportOverview";
import CashupHeader from "../V2/Navigation/CashupHeader";
import Login from "../Pages/UserManagement/Login";

const PosTable = () => {
    return (
        <DefaultLayout BodyComponent={() => <CashupHeader />} skipLayoutWrapper />
    );
};

export default function AppRouter(): ReactElement {
    return (
        <Suspense fallback={QuantacoLoader}>
            <Switch>
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.HOME}
                    component={PosTable}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.POS}
                    component={PosTable}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.Gaming}
                    component={PosTable}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.Wagering}
                    component={PosTable}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.ATM}
                    component={PosTable}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.CCU}
                    component={PosTable}
                />{" "}
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.CRT}
                    component={PosTable}
                />{" "}
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.Office}
                    component={PosTable}
                />{" "}
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.Safe}
                    component={PosTable}
                />{" "}
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.Review}
                    component={PosTable}
                />
                <AuthProtectedRoute
                    path={RoutePaths.DailyShiftReport}
                    component={PosTable}
                    exact
                />
                <AuthProtectedRoute
                    path={RoutePaths.CASHUP_REPORT_SUMMARY}
                    component={CashupReportOverview}
                    exact
                />
                <AuthProtectedRoute
                    path={RoutePaths.AUDIT_REPORT}
                    component={AuditReportPage}
                />{" "}
                <Route exact path={RoutePaths.LOGIN} component={Login} />
                <Route
                    exact
                    path={RoutePaths.UNAUTHORISED}
                    component={UnAuthorised}
                />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Suspense>
    );
}
